import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white h-100"},[_c('div',{staticClass:"h-100",class:_vm.$route.name === 'Asset Library' ? 'main-container' : 'pt-4'},[(_vm.loading)?_c('Loader',{staticStyle:{"height":"60vh"}}):_c('div',[(_vm.$route.name === 'Asset Library')?_c(VCol,{class:_vm.$vuetify.breakpoint.mdAndUp ? 'pb-10' : 'pb-4'},[_c(VCol,[_c('PageTitle')],1)],1):_vm._e(),(_vm.showUploadAssetModal)?_c('UploadAssetModal',{attrs:{"open":_vm.showUploadAssetModal,"isClient":_vm.isClient,"orderId":"null","deliverable":{},"page":"asset_library","selectedWorkspaceId":_vm.selectedWorkspaceId,"enableSharingWithBusinesses":_vm.showShared},on:{"updateContent":_vm.getContent,"close":_vm.onCloseUploadAssetsForm}}):_vm._e(),_c(VCol,{staticClass:"d-flex pt-2",attrs:{"cols":"12"}},[_c(VRow,{staticClass:"ma-auto"},[_c(VCol,{attrs:{"cols":"12","xs":"12","sm":"12","md":"3","lg":"3","xl":"3","xxl":"3"}},[(_vm.page !== 'org_business' && _vm.workspaceList.length > 1)?_c(VRow,{staticClass:"dropdown ma-auto"},[_c('div',[_c(VSelect,{staticClass:"notranslate filterMethods",attrs:{"items":_vm.workspaceList,"item-text":"text","item-value":"value","append-icon":"keyboard_arrow_down","solo":"","flat":""},on:{"change":_vm.onSwitchBrandProfile},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.text)+" ")]),_c('p',{staticClass:"selected"},[_vm._v(_vm._s(item.type))])]}}],null,false,2221101648),model:{value:(_vm.selectedWorkspaceId),callback:function ($$v) {_vm.selectedWorkspaceId=$$v},expression:"selectedWorkspaceId"}})],1)]):_vm._e(),_c(VRow,[_c(VCol,{staticClass:"d-flex pt-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"heading1 font-label"},[_vm._v("Folders")])]),_c('div',[_c('div',{staticClass:"font-h5",class:_vm.addClass('brand'),on:{"click":function($event){return _vm.changeTab('brand')}}},[_vm._v(" Brand Files ")])])],1),(_vm.contentKits.length)?_c(VRow,{staticClass:"contentKits"},[_c(VCol,{staticClass:"d-flex pt-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"contentKitHeading font-label"},[_vm._v("CATEGORIES")])]),_vm._l((_vm.contentKits),function(item){return _c('div',{key:("content-kit-" + (item.fileCategories
                ? item.fileCategories.id : item.categoryId)),staticClass:"kits"},[_c('div',{staticClass:"a-link",class:_vm.addClass(item.fileCategories ? item.fileCategories.id : item.categoryId),on:{"click":function($event){return _vm.changeTab(item.fileCategories ? item.fileCategories.id : item.categoryId)}}},[_vm._v(" "+_vm._s(item.fileCategories ? item.fileCategories.name : item.categoryId)+" ")])])})],2):_vm._e(),(_vm.canUploadContentKits || _vm.isClient)?_c(VRow,{staticClass:"contentKits"},[_c(VCol,{staticClass:"d-flex pt-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"contentKitHeading font-label"},[_vm._v("Actions")])]),_c('div',[_c('div',{class:_vm.addClass('upload'),on:{"click":function($event){_vm.showUploadAssetModal = true}}},[_c('img',{staticClass:"download-icon",attrs:{"src":require("@/assets/svg/upload-thick.svg"),"width":"24","height":"14"}}),_vm._v(" Upload ")])])],1):_vm._e()],1),_c(VCol,{attrs:{"cols":"12","xs":"12","sm":"12","md":"9","lg":"9","xl":"9","xxl":"9"}},[_c(VRow,{staticClass:"assets ma-auto pt-2"},[_c(VCol,{attrs:{"cols":"12"}},[_c('ContentKitAssets',{attrs:{"selectedProjectId":_vm.selectedWorkspaceId,"utilityId":_vm.selectedTab,"currentTab":_vm.currentTab,"reference":"media_library","showShared":_vm.showShared,"canUploadContentKits":_vm.canUploadContentKits || _vm.isClient,"page":_vm.page},on:{"onSaveAssets":_vm.onSaveAssets,"getContent":_vm.getContent}})],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }