import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"selected-file-container ma-auto"},[_c(VCol,{attrs:{"cols":"6"}},[_c('div',{staticClass:"left-container"},[_c('div',{staticClass:"file-preview"},[(_vm.file.preview.src && ['image', 'video'].includes(_vm.file.preview.type))?_c('img',{attrs:{"src":_vm.file.preview.src}}):_c(VIcon,{attrs:{"size":"68","color":"#f2f2f2"}},[_vm._v(" mdi-file-outline ")])],1),_c('div',{staticClass:"metadata"},[_c('div',{staticClass:"file-name"},[_c(VTextField,{staticClass:"file-name-input",attrs:{"placeholder":"File Name","outlined":"","dense":"","hide-details":!Boolean(_vm.error),"error-messages":_vm.error},model:{value:(_vm.file.name),callback:function ($$v) {_vm.$set(_vm.file, "name", $$v)},expression:"file.name"}})],1),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_vm._l((_vm.file.suggestedPlatforms),function(tag){return _c('div',{key:tag,staticClass:"tag"},[_vm._v(" "+_vm._s(tag)+" ")])}),[_c('div',{staticClass:"text-center"},[(_vm.unselectedSocialTags.length > 0)?_c(VMenu,{attrs:{"offset-x":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"add-tag-icon"},'div',attrs,false),on),[_vm._v("+")])]}}],null,false,2671645696)},[_c(VList,_vm._l((_vm.unselectedSocialTags),function(item){return _c(VListItem,{key:item.name,on:{"click":function($event){return _vm.appendTag(item)}}},[_c(VListItemTitle,[_vm._v(_vm._s(item.name))])],1)}),1)],1):_vm._e()],1)]],2)])])]),_c(VCol,{attrs:{"cols":"6"}},[_c('div',{staticClass:"selected-file-size d-flex justify-space-between"},[_c('div',[_vm._v("Size: "),_c('span',{staticClass:"text-gray"},[_vm._v(" "+_vm._s(_vm.file.formattedSize)+" ")])]),_c('div',{staticClass:"cross-mark",on:{"click":function($event){return _vm.$emit('remove')}}},[_c(VIcon,{staticClass:"close cursor-pointer"},[_vm._v("mdi-close")])],1)]),_c('div',{staticClass:"selected-file-size"},[_vm._v("Dimensions: "),(['image', 'video'].includes(_vm.file.preview.type))?_c('span',{staticClass:"text-gray"},[_vm._v(" "+_vm._s(_vm.dimensions)+" ")]):_c('span',{staticClass:"text-gray"},[_vm._v(" Not Available ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }