<!-- eslint-disable max-len -->
<template>
<svg :width="width" :height="height" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_6450_62910)">
<path d="M10.3837 12.9503L7.9372 15.3968C7.2269 16.0882 6.27296 16.472 5.28179 16.4654C4.29062 16.4587 3.34192 16.0621 2.64094 15.3613C1.93996 14.6606 1.54307 13.712 1.53615 12.7208C1.52923 11.7296 1.91283 10.7756 2.60395 10.0651L5.05046 7.61634C5.19109 7.47561 5.27005 7.28478 5.26998 7.08582C5.26991 6.88687 5.19081 6.6961 5.05008 6.55546C4.90935 6.41483 4.71852 6.33587 4.51956 6.33594C4.32061 6.33601 4.12984 6.41511 3.9892 6.55584L1.54345 9.00459C0.555158 9.99338 0.000136807 11.3343 0.000488448 12.7323C0.000840089 14.1303 0.556536 15.4709 1.54533 16.4592C2.53412 17.4475 3.87502 18.0025 5.27303 18.0022C6.67104 18.0018 8.01166 17.4461 8.99995 16.4573L11.4465 14.0108C11.5831 13.8694 11.6587 13.6799 11.657 13.4833C11.6553 13.2866 11.5764 13.0985 11.4373 12.9595C11.2983 12.8204 11.1102 12.7415 10.9135 12.7398C10.7169 12.7381 10.5274 12.8137 10.386 12.9503H10.3837Z" :fill="color"/>
<path d="M16.4579 1.54577C15.9698 1.05438 15.3891 0.664727 14.7494 0.399391C14.1097 0.134054 13.4237 -0.00169234 12.7311 1.84806e-05C12.0389 -0.00182032 11.3532 0.133577 10.7137 0.398382C10.0741 0.663187 9.49341 1.05215 9.00511 1.54277L6.55486 3.99002C6.41413 4.13065 6.33503 4.32143 6.33496 4.52038C6.33489 4.71933 6.41386 4.91016 6.55449 5.05089C6.69512 5.19162 6.8859 5.27073 7.08485 5.2708C7.2838 5.27087 7.47463 5.1919 7.61536 5.05127L10.0641 2.60477C10.4133 2.25351 10.8287 1.975 11.2862 1.78536C11.7437 1.59573 12.2343 1.49874 12.7296 1.50002C13.4753 1.50027 14.2042 1.72159 14.8241 2.13602C15.4441 2.55044 15.9272 3.13937 16.2125 3.82833C16.4978 4.5173 16.5724 5.27538 16.4269 6.00675C16.2814 6.73812 15.9224 7.40993 15.3951 7.93727L12.9486 10.3838C12.8079 10.5245 12.7288 10.7154 12.7288 10.9144C12.7288 11.1134 12.8079 11.3043 12.9486 11.445C13.0893 11.5858 13.2802 11.6648 13.4792 11.6648C13.6783 11.6648 13.8691 11.5858 14.0099 11.445L16.4564 9.00002C17.4434 8.01084 17.9978 6.6706 17.9981 5.2732C17.9984 3.87581 17.4445 2.53534 16.4579 1.54577Z" :fill="color"/>
<path d="M10.7197 6.21981L6.21969 10.7198C6.14806 10.789 6.09092 10.8718 6.05161 10.9633C6.01231 11.0548 5.99162 11.1532 5.99075 11.2528C5.98989 11.3523 6.00886 11.4511 6.04657 11.5433C6.08428 11.6354 6.13997 11.7192 6.21039 11.7896C6.28081 11.86 6.36455 11.9157 6.45672 11.9534C6.5489 11.9911 6.64766 12.0101 6.74724 12.0092C6.84682 12.0084 6.94524 11.9877 7.03674 11.9484C7.12825 11.9091 7.211 11.8519 7.28019 11.7803L11.7802 7.28031C11.9168 7.13886 11.9924 6.94941 11.9907 6.75276C11.989 6.55611 11.9101 6.368 11.7711 6.22895C11.632 6.08989 11.4439 6.01101 11.2472 6.00931C11.0506 6.0076 10.8611 6.08319 10.7197 6.21981Z" :fill="color"/>
</g>
<defs>
<clipPath id="clip0_6450_62910">
<rect :width="width" :height="height" fill="white"/>
</clipPath>
</defs>
</svg>
</template>

<script>
export default {
  name: 'Link',
  props: {
    color: {
      type: String,
      default: '#262626',
    },
    width: {
      type: String,
      default: '18',
    },
    height: {
      type: String,
      default: '18',
    },
  },
};
</script>
