<template>
    <v-dialog
        v-model="open"
        persistent
        max-width="750px"
    >
        <v-card
            style="border-radius: unset !important"
            class="upload-modal-card-container"
            @dragover.prevent @drop.prevent
        >
            <v-row class="ma-auto title-header">
                <v-col class="pa-0">
                  <div class="modal-header-title">Upload Asset(s)</div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class=" d-flex justify-end pa-0">
                  <v-icon color="darken-1" @click="$emit('close')">mdi-close</v-icon>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <div class="main-body">
                <v-row class="deliverable-info-container" v-if="page !== 'asset_library'">
                    <v-col cols="6">
                        <div class="deliverable-info">
                            <div class="del-cat-type">
                              {{deliverable.deliverable.category.name}}
                            </div>
                            <div class="del-name">
                              <!-- eslint-disable-next-line -->
                              {{`${deliverable.deliverable.name}(${deliverable.deliverable.creditCost * deliverable.quantity} Credits)`}}
                            </div>
                            <div class="del-description">{{ deliverable.description || '-' }}</div>
                        </div>
                    </v-col>
                    <v-col>
                        <div class="meta-info">
                            <div class="meta-item">
                                <div class="meta-title">Dimensions</div>
                                <div class="meta-value">{{deliverableDimensions}}</div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <div class="file-chooser-container my-4">
                    <div class="file-chooser">
                        <div class="label form-label mb-2">File(s)</div>
                        <div class="chooser-container" @click="openFileSelector" @drop="drop">
                            <div class="placeholder d-flex align-center">
                                <div class="d-flex align-center mr-1">
                                    <!-- upload icon -->
                                    <img
                                        src="@/assets/svg/upload-gray.svg"
                                        class="upload-icon"
                                        width=20
                                        height=12
                                      />
                                </div>
                                <div class="text">Add File(s)</div>
                                <!-- eslint-disable-next-line -->
                                <input type="file" ref="fileSelector" hidden accept="'.jpg,.jpeg,.png,.pdf,.gif,.ai,.psd,.mp4,.mov,.doc,.docx,.srt'" @change="onFileSelectorChange" multiple />
                                <!-- @change="onFileSelectorChange" -->
                            </div>
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>
                <div
                class="selected-files-container"
                v-if="selectedFiles && selectedFiles.length">
                    <div
                      class="selected-files-loop"
                      v-for="(file, index) in selectedFiles"
                      :key="index"
                    >
                      <UploadFileLineItem
                        :file="file"
                        :fileNameErrors="errors.fileNameErrors"
                        @remove="onFileRemove(index)"
                      />
                    </div>
                </div>
                <div class="creative-chooser mt-4" v-if="!isClient">
                    <!-- label with Creative(s) and dropdown of multi select to select creatives -->
                    <div class="label font-label mb-2">Creative(s)</div>
                    <div>
                        <v-select
                            v-model="selectedCreatives"
                            :items="creatives"
                            placeholder="Select..."
                            multiple
                            outlined
                            item-text="name"
                            item-value="id"
                            class="creative-select"
                            chips
                            dense
                            append-icon="keyboard_arrow_down"
                            :error-messages="errors.creatives"
                        ></v-select>
                    </div>
                </div>
                <div class="utility-chooser">
                    <!-- label with Creative(s) and dropdown of multi select to select creatives -->
                    <div class="label font-label mb-2">Category</div>
                    <div>
                        <v-select
                            v-model="selectedUtilities"
                            :items="categories"
                            multiple
                            placeholder="Select..."
                            outlined
                            item-text="name"
                            item-value="id"
                            chips
                            dense
                            class="category-select"
                            append-icon="keyboard_arrow_down"
                            :error-messages="errors.utilities"
                        ></v-select>
                    </div>
                </div>
                <div class="business-chooser" v-if="enableSharingWithBusinesses">
                    <!-- label with Creative(s) and dropdown of multi select to select creatives -->
                    <div class="label font-label mb-2">Share with affiliated businesses</div>
                    <div>
                        <v-select
                            v-model="selectedBusinessesToShareWith"
                            :items="agencyBusinesses"
                            placeholder="Select..."
                            outlined
                            multiple
                            item-text="name"
                            item-value="id"
                            class="business-select"
                            chips
                            dense
                            append-icon="keyboard_arrow_down"
                            :error-messages="errors.businesses"
                        ></v-select>
                    </div>
                </div>
                <!-- card actions cancel and upload -->
                <v-card-actions class="pt-0">
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primaryGray1"
                        @click="$emit('close')">Cancel</v-btn>
                    <v-btn
                        :loading="isUploading"
                        :disabled="selectedFiles.length === 0"
                        class="upload-button btn-purple"
                        @click.native="upload">Upload</v-btn>
            </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import UploadFileLineItem from './UploadFileLineItem';

export default {
  name: 'UploadAssetsModal',
  components: {
    UploadFileLineItem,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    deliverable: {
      type: Object,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    enableSharingWithBusinesses: {
      type: Boolean,
      default: false,
    },
    page: {
      default: 'Order',
    },
    selectedWorkspaceId: {
      default: false,
    },
    isClient: {
      default: false,
    },
  },
  data() {
    return ({
      selectedCreatives: [],
      selectedUtilities: [],
      selectedBusinessesToShareWith: [],
      selectedFiles: [],
      errors: {},
      isUploading: false,
    });
  },
  async mounted() {
    await this.getFileCategories();
    await this.getSocialTags();
    await this.getRecentSweepCreatives({
      businessId: this.business.id,
    });
    await this.getAgencyBusinesses({
      businessId: this.business.id,
      status: 1,
    });
    await this.getProjectTeam(this.business.projects[0].id);
  },
  computed: {
    ...mapGetters('project', ['producersTeam', 'creativeTeam', 'executiveTeam', 'allCreatives']),
    ...mapGetters('business', ['business', 'categories']),
    ...mapGetters('organizations', ['agency', 'agencyBusinesses']),
    ...mapGetters('sweep', ['recentSweepCreatives']),
    creatives() {
      const recentCreatives = this.recentSweepCreatives.map((creative) => ({
        id: creative.id,
        name: `${creative.firstName} ${creative.lastName}`,
      }));
      // eslint-disable-next-line
      let otherCreatives = _.map(this.projectTeam, (creative) => ({ id: creative.id, name: `${creative.firstName} ${creative.lastName}` }));
      // filter other creatives to remove who are in recentCreatives
      // eslint-disable-next-line
      otherCreatives = otherCreatives.filter((creative) => !recentCreatives.find((c) => c.id === creative.id));
      const result = [];
      if (recentCreatives.length) {
        result.push({ header: 'Recent Creatives' });
        result.push(...recentCreatives);
      }
      if (recentCreatives.length) {
        result.push({ header: 'Other Creatives' });
      }
      result.push(...otherCreatives);
      return result;
    },
    projectTeam() {
      return [
        ..._.chain(this.producersTeam)
          .filter((producer) => producer.accepted === '1')
          .map((producer) => producer.user)
          .value(),
        ..._.chain(this.creativeTeam)
          .filter((creative) => creative.accepted === '1')
          .map((creative) => creative.user)
          .value(),
        ..._.chain(this.executiveTeam)
          .filter((executive) => executive.accepted === '1')
          .map((executive) => executive.user)
          .value(),
        ..._.chain(this.allCreatives),
      ];
    },
    deliverableDimensions() {
      const dimensionsList = _.get(this.deliverable, 'dimensions', []);
      const dimensions = dimensionsList.map(({ dimension }) => dimension.name);
      if (dimensions.length) return dimensions.join(', ');
      return 'N/A';
    },
  },
  methods: {
    ...mapActions(['setNotification']),
    ...mapActions('organizations', ['getAgencyBusinesses']),
    ...mapActions('orders', ['uploadAssets', 'getOrderFiles', 'uploadAssetLibrary']),
    ...mapActions('project', ['getProjectTeam']),
    ...mapActions('contentKit', ['getSocialTags']),
    ...mapActions('business', ['getFileCategories']),
    ...mapActions('sweep', ['getRecentSweepCreatives']),
    validate() {
      this.errors = {};
      if (!this.selectedFiles.length) {
        this.errors.files = 'At least one file must be selected';
      } else {
        this.errors.files = null;
      }
      // if (!this.selectedCreatives.length) {
      //   this.errors.creatives = 'At least one creative must be selected';
      // } else {
      //   this.errors.creatives = null;
      // }
      if (!this.selectedUtilities.length) {
        this.errors.utilities = 'At least one category must be selected';
      } else {
        this.errors.utilities = null;
      }
      this.errors.fileNameErrors = {};
      // validate file names, shouldn't contain special characters except .-_
      this.selectedFiles.forEach(({ name: fileName, id }) => {
        if (!fileName.match(/^[a-zA-Z0-9- _.]+$/)) {
          // eslint-disable-next-line
          this.errors.fileNameErrors[id] = 'File names can only contain letters, numbers, -, _ and .';
        } else if (fileName.length > 50) {
          // eslint-disable-next-line
          this.errors.fileNameErrors[id] = 'File names cannot be longer than 50 characters';
        } else {
          delete this.errors.fileNameErrors[id];
        }
      });
      if (Object.values(this.errors.fileNameErrors).length === 0) {
        this.errors.fileNameErrors = null;
      }
      return Object.values(this.errors).filter((error) => error).length === 0;
    },
    getFormattedFileSize(size) {
      // should return kb or mb
      if (size < 1024) {
        return `${size} bytes`;
      }
      // 1048576 = 1024 * 1024 = 1 mb
      if (size < 1048576) {
        return `${(size / 1024).toFixed(2)} KB`;
      }
      return `${(size / 1048576).toFixed(2)} MB`;
    },
    openFileSelector() {
      this.$refs.fileSelector.click();
    },
    drop(event) {
      event.preventDefault();
      this.$refs.fileSelector.files = event.dataTransfer.files;
      this.onFileSelectorChange();
    },
    async onFileSelectorChange() {
      this.$refs.fileSelector.files.forEach(async (file) => {
        const maxFileSize = parseInt(process.env.VUE_APP_ASSET_UPLOAD_SIZE, 10) || 3221225472;
        if (file.size > maxFileSize) {
          this.setNotification({
            message: `${file.name} is too large. Files must be less than 3GB.`,
            type: 'error',
          }, { root: true });
          return;
        }
        const previewInfo = await this.getPreviewDataForTheFile(file);
        const extension = file.name.split('.').pop();
        // const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
        const fileName = file.name;
        let suggestedPlatforms = [];
        if (previewInfo.width && previewInfo.height) {
          const dimensionName = this.getDimensionName(previewInfo.width, previewInfo.height);
          suggestedPlatforms = this.getPlatforms(dimensionName);
        }
        this.selectedFiles.push({
          id: _.uniqueId(),
          name: fileName,
          extension,
          file,
          formattedSize: this.getFormattedFileSize(file.size),
          preview: previewInfo,
          suggestedPlatforms,
        });
      });
    },
    // should create thumbnail, size, dimensions, filename , etc from file metadata.
    // .jpg,.jpeg,.png,.pdf,.gif,.ai,.psd,.mp4,.mov,
    async getPreviewDataForTheFile(file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      // image types which img tag can show
      const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
      // video types which video tag can show
      const videoTypes = ['mp4', 'mov', 'avi', 'wmv', 'flv', 'webm', 'ogv'];
      // audio types which audio tag can show - not accepting audio right now
      // const audioTypes = ['mp3', 'wav', 'ogg', 'aac', 'flac', 'wma', 'm4a'];
      // document types which document tag can show
      const documentTypes = ['.doc', '.docx', '.srt'];
      if (imageTypes.includes(fileExtension)) {
        const { thumbnail, width, height } = await this.getImageMetadata(file);
        return {
          type: 'image',
          src: thumbnail,
          width,
          height,
        };
      }
      if (videoTypes.includes(fileExtension)) {
        const { thumbnail, width, height } = await this.getVideoMetadata(file);
        return {
          type: 'video',
          src: thumbnail,
          width,
          height,
        };
      }
      //   if (audioTypes.includes(fileExtension)) {
      //     return {
      //       type: 'audio',
      //       src: URL.createObjectURL(file),
      //     };
      //   }
      if (documentTypes.includes(fileExtension)) {
        return {
          type: 'document',
          src: URL.createObjectURL(file),
        };
      }
      return {
        type: 'file',
        src: URL.createObjectURL(file),
      };
    },
    async getVideoMetadata(file) {
      return new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        const video = document.createElement('video');
        // this is important
        video.autoplay = true;
        video.muted = true;
        video.src = URL.createObjectURL(file);
        video.onerror = () => {
          URL.revokeObjectURL(video.src);
          resolve({
            // eslint-disable-next-line
            thumbnail: '',
            width: null,
            height: null,
          });
        };
        video.onloadeddata = () => {
          const ctx = canvas.getContext('2d');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
          video.pause();
          URL.revokeObjectURL(video.src);
          return resolve({
            thumbnail: canvas.toDataURL('image/png'),
            width: video.videoWidth,
            height: video.videoHeight,
          });
        };
      });
    },
    async getImageMetadata(file) {
      return new Promise((resolve) => {
        const img = document.createElement('img');
        img.src = URL.createObjectURL(file);
        img.onload = () => resolve({
          thumbnail: img.src,
          width: img.width,
          height: img.height,
        });
      });
    },
    onFileRemove(index) {
      this.selectedFiles.splice(index, 1);
    },
    getDimensionName(width, height) {
      switch (_.round(width / height, 2)) {
        case 0.56:
          return '9 x 16';
        case 0.67:
          return '4 x 6';
        case 0.8:
          return '4 x 5';
        case 1:
          return '1 x 1';
        case 1.5:
          return '6 x 4';
        case 1.78:
          return '16 x 9';
        default:
          return 'custom';
      }
    },
    getPlatforms(dimensionName) {
      switch (dimensionName) {
        case '1 x 1':
        case '4 x 5':
          return ['Instagram', 'Facebook'];
        case '9 x 16':
          return ['Instagram Stories', 'Facebook Stories', 'Tik Tok'];
        case '16 x 9':
          return ['Facebook', 'LinkedIn', 'Instagram TV', 'YouTube', 'Website'];
        case '4 x 6':
        case '6 x 4':
          return ['Facebook'];
        default:
          return [];
      }
    },
    async upload() {
      try {
        const isValid = this.validate();
        if (!isValid) return;
        this.isUploading = true;
        const formData = new FormData();
        const filesMetadata = {};
        this.selectedFiles.forEach((fileData, index) => {
          // eslint-disable-next-line
          const types = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'mp4', 'mov', 'avi', 'wmv', 'flv', 'webm', 'ogv', 'doc', 'docx','pdf', 'srt'];
          let newFilename = `file${index}.${fileData.name}`;
          // check if extension exists
          const extension = newFilename.split('.').pop();
          if (!types.includes(extension.toLowerCase())) {
            newFilename = `${newFilename}.${fileData.extension}`;
          }
          // remove file index from name at backend.
          const renamedFile = new File([fileData.file], newFilename, {
            type: fileData.file.type,
          });
          formData.append('files', renamedFile);
          filesMetadata[newFilename] = {
            platforms: fileData.suggestedPlatforms,
          };
        });
        formData.append('filesMetadata', JSON.stringify(filesMetadata));
        formData.append('creatives', JSON.stringify(this.selectedCreatives));
        formData.append('categories', JSON.stringify(this.selectedUtilities));
        formData.append('businesses', JSON.stringify(this.selectedBusinessesToShareWith));
        formData.append('orderId', this.orderId);
        formData.append('deliverableId', this.deliverable.id);
        formData.append('selectedWorkspaceId', this.selectedWorkspaceId);
        if (this.page !== 'asset_library') {
          const response = await this.uploadAssets(formData);
          if (response) {
            // refresh
            this.getOrderFiles({ orderId: this.orderId });
            this.$emit('close');
          }
        } else {
          const response = await this.uploadAssetLibrary(formData);
          if (response) {
            this.$emit('updateContent');
            this.$emit('close');
          }
        }
        this.isUploading = false;
      } catch (error) {
        this.setNotification({
          message: error.message,
          type: 'error',
        }, { root: true });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.creative-chooser, .utility-chooser, .business-chooser {
  ::v-deep {
    .v-text-field input::placeholder {
      color: #515151;
    }
    .v-chip__content {
      color: #262626;
    }
  }
}
.title-header {
    border-bottom: 1px solid #e0e0e0;
    padding: 16px 21px 13px 21px;
  }
.main-body {
  padding: 2rem;
  padding-bottom: 1rem;
  .deliverable-info-container {
      margin: 0rem;
  }
}
.deliverable-info-container{
    background: #F2F2F2;
}
.chooser-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #DDDFE1;
    border-radius: 5px;
    padding: 1.5rem 2rem;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #828282;
}
.del-cat-type,
.meta-title
{
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #999999;
}
.meta-value {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
}
.del-name{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
}
.del-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #999999;
}
.creative-select {
    width: 100%;
}
.upload-button {
    padding: 1rem 1.5rem !important;
    ::v-deep .v-btn__content {
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
    }
}

::v-deep .v-subheader {
  // font-family: $fontFamily1;
  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  color: #999999;
  padding: 0 16px;
}

</style>
