import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showBreadcrumbs)?_c('div',[_c(VBreadcrumbs,{staticClass:"app-bar-title px-0 pb-0",class:_vm.title === 'Content Kit' ? 'multiple' : 'single',attrs:{"items":_vm.items,"large":"","exact":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VBreadcrumbsItem,{class:item.disabled ? 'breadcrumb-item' : '',attrs:{"to":item.to,"disabled":item.disabled,"exact":""}},[_c('h1',{staticClass:"text-ellipsis font-h1",class:item.text === 'Projects' ||
              item.text === 'Dashboard' ||
              item.text === 'Asset Library'
                ? ''
                : 'notranslate'},[_vm._v(" "+_vm._s(item.text)+" ")])])]}},{key:"divider",fn:function(){return [_c(VIcon,{staticClass:"pt-1",attrs:{"size":"24","color":"charcoal"}},[_vm._v("mdi-chevron-right")])]},proxy:true}],null,false,3401552488)})],1):_c('div',{key:_vm.title},[(_vm.titleChecking)?_c(VToolbarTitle,{staticClass:"app-bar-title"},[_c('h1',{key:_vm.title,staticClass:"font-h1"},[_vm._v(_vm._s(_vm.name || _vm.title))])]):_c(VToolbarTitle,{staticClass:"app-bar-title pl-1"},[(_vm.$vuetify.breakpoint.smAndUp)?_c(VIcon,{attrs:{"size":"24","color":"charcoal"},on:{"click":_vm.onClick}},[_vm._v(" mdi-arrow-left ")]):_vm._e(),_vm._v(" Your "+_vm._s(_vm.title)+" ")],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }