<template>
  <div>
    <div v-if="showBreadcrumbs">
      <v-breadcrumbs
        :items="items"
        large
        class="app-bar-title px-0 pb-0"
        :class="title === 'Content Kit' ? 'multiple' : 'single'"
        exact
      >
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
            exact
            :class="item.disabled ? 'breadcrumb-item' : ''"
          >
            <h1
              class="text-ellipsis font-h1"
              :class="
                item.text === 'Projects' ||
                item.text === 'Dashboard' ||
                item.text === 'Asset Library'
                  ? ''
                  : 'notranslate'
              "
            >
              {{ item.text }}
            </h1>
          </v-breadcrumbs-item>
        </template>
        <template v-slot:divider>
          <v-icon class="pt-1" size="24" color="charcoal">mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <div v-else :key="title">
      <v-toolbar-title v-if="titleChecking" class="app-bar-title">
        <h1 class="font-h1" :key="title">{{ name || title }}</h1>
      </v-toolbar-title>
      <v-toolbar-title v-else class="app-bar-title pl-1">
        <v-icon size="24" color="charcoal" @click="onClick" v-if="$vuetify.breakpoint.smAndUp">
          mdi-arrow-left
        </v-icon>
        Your {{ title }}
      </v-toolbar-title>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'PageTitle',
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('project', ['currentProject', 'userProjects']),
    ...mapGetters('contentKit', ['currentContentKit']),
    title() {
      switch (this.$route.name) {
        case 'Client Content Kit':
          return this.currentContentKitName;
        case 'Customer Brand Kit':
          return 'Brand Kit';
        case 'Customer Management':
        case 'User Management':
          return 'Users';
        case 'Project':
          return 'Businesses';
        case 'Organization':
          if (this.agency_customer) {
            return 'Businesses';
          }
          return 'Organization';
        default:
          return this.$route.name;
      }
    },
    titleChecking() {
      if (this.title !== 'Notifications' && this.title !== 'Content Plan') {
        return true;
      }
      return false;
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    agency_customer() {
      return _.includes(['agency_owner', 'agency_member'], this.role);
    },
    currentContentKitName() {
      return _.get(this.currentContentKit, 'name', '');
    },
    items() {
      const items = _.concat(this.$route.meta.items, {
        text: _.get(this.currentProject, 'business.name', ''),
        disabled: true,
      });
      switch (this.$route.name) {
        case 'Content Kit': {
          const data = items;
          const { navBackProjectId } = this.$route.query;
          let currentProject = data.splice(1, 2);
          currentProject = {
            text: this.currentProject.name,
            // eslint-disable-next-line
            to: `/project_overview/${navBackProjectId || this.currentProject.id}?businessId=${
              this.currentProject.businessId
            }`,
            disabled: false,
          };
          const kitName = _.concat(currentProject, {
            text: this.currentContentKitName,
            disabled: true,
          });
          if (this.role === 'client') {
            return [kitName];
          }
          return _.concat(items, kitName);
        }
        case 'Project Team': {
          if (this.role === 'client') {
            return [];
          }
          return items;
        }
        case 'Asset Library': {
          const item = { text: 'Asset Library', disabled: true };
          return [item];
        }
        case 'Assets': {
          const item = { text: 'All Files', disabled: true };
          return _.concat(this.$route.meta.items, item);
        }
        case 'Client Content Kit': {
          if (this.agency_customer) {
            const data = items;
            let prevItem = data.splice(0, 1);
            prevItem = {
              text: 'Asset Library',
              to: `/organization/${this.userDetails.currentBusinessId}/library`,
              disabled: false,
            };
            return _.concat(prevItem, { text: this.currentContentKitName, disabled: true });
          }
          const item = { text: this.currentContentKitName, disabled: true };
          return _.concat(this.$route.meta.items, item);
        }
        default:
          return items;
      }
    },
    showBreadcrumbs() {
      return this.$route.meta.showBreadcrumbs && this.$vuetify.breakpoint.smAndUp;
    },
  },
};
</script>
