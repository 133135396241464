<template >
  <div :key="items.length">
    <RemoveAssetConfirmationModel ref="removeAssetConfirmationModel"/>
    <div v-if="items.length">
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-row class="ma-0 content-kit-files-grid align-stretch" v-if="showItemsInGrid">
        <div md=6 lg=4 xl=4 sm=12  class="pt-0 pb-1 mb-5 file-padding"
          v-for="(item) in items" :key="`content-kit-${item.id}`">
          <div class="content-kit-file-container"
           :class="{active: isSelected(item.id), pagecustomer: isBusiness }">
            <v-checkbox
              v-model="selected"
              :value="item.id"
              dense
              class='multi-check'
              off-icon="mdi-checkbox-blank-circle-outline"
              on-icon="mdi-checkbox-marked-circle"
              @change="(e) => onFileCheckChange(item.id, e)"
            ></v-checkbox>
            <content-kit-file
              :item="item"
              @fileRename="renameFile"
              @onArchive="archiveFile"
              @onDelete="deleteFile"
              @onDownload="downloadFiles(item.id)"
              :activeClass="{ active: isSelected(item.id) }"
              :isProjectActive="isProjectActive"
              :section="section"
              @onPreview="previewClick"
              @select="selectFile"
              class="content-kit-file"
              :utilityId="utilityId"
              :selectedProjectId="selectedProjectId"
            />
          </div>
        </div>
        <Observer @intersect="intersected"/>
      </v-row>
      <v-row  class="ma-0 content-kit-files-list" v-else>
        <v-col cols=12>
          <v-data-table
            :headers="headers"
            :items="items"
            item-key="id"
            hide-default-footer
            disable-pagination
          >
            <template  v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  @click="(e) => previewClick(item.id, e)"
                >
                  <td  width="40%">
                    <div class="item-name notranslate"
                    :class="{'item-names': !$vuetify.breakpoint.lg}">
                    <div class="item-logo">
                    <div v-if="item.fileType === '2' && ['mov', 'mp4'].includes(fileFormat(item))"
        class="content-kit-play">
          <v-icon size="10" class="play-btn">mdi-play</v-icon>
        </div>
          <template v-if="item.fileType === '1'">
            <img
              :src="getOptimizedS3ImageURL(cardImage(item), { width: 30, height: 30 })"
              alt="no-image"
              :class="{'default-image': !validImage(item)}"
            />
          </template>
          <template v-else-if="item.fileType === '2'">
            <div class="card-image d-flex justify-center align-center"
            >
              <img
                :src="getOptimizedS3ImageURL(poster(item), { width: 30, height: 30 })"
                :class="{'default-image': !item.poster}"
              />
            </div>
            <!-- <div class="card-image d-flex justify-center align-center" v-else>
              <img
              :src="getOptimizedS3ImageURL(cardImage, { width: 200, height: 200 })"
              alt="no-image"
              :class="{'default-image': !validImage}"
            /> -->
            <!-- </div> -->
          </template>
          <template v-else>
            <div
              class="long-copy d-flex justify-center align-center"
            >
              <img v-if="fileFormat(item) === 'pdf'"
                width="30px"
                :src="require(`@/assets/svg/theme/pdf.svg`)" class="default-image"
              />
              <img v-else width="30px"
               :src="require(`@/assets/svg/theme/doc.svg`)" class="default-image" />
              <!-- <embed
                :src="item.file"
                type="application/pdf" width="100%" height="140px"
                v-on:click="showPreview"
                id="embed-tag"
                ref="embed-tag"
              /> -->
            </div>
          </template>
          </div>
          <template
            v-if="!['inspiration', 'brand'].includes(utilityId) && (canUpdate || isClient)">
                        <v-edit-dialog
                          @open="setCurrentItemName(item)"
                          @save="updateFileName(item)"
                          @cancel="() => {}"
                          @close="updateFileName(item)"
                        > {{ item.name }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="currentItemName"
                              label="Edit"
                              single-line
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-else> {{ item.name }} </template>
                    </div>
                      <div class="user-names" :class="{'user-name': !$vuetify.breakpoint.xs}">
                      {{ item.uploadedUser && item.uploadedUser.firstName}}
                      </div>
                      <div class="user-names" :class="{'user-name': !$vuetify.breakpoint.xs}">
                         {{formatedDate(item.updatedAt)}}
                      </div>
                  </td>
                  <td width="30%" class="notranslate"
                  :class="{'image-name': !$vuetify.breakpoint.md && !$vuetify.breakpoint.lg &&
                !$vuetify.breakpoint.sm}">
                      {{ item.uploadedUser && item.uploadedUser.firstName}}
                  </td>
                  <td width="20%" :class="{'image-name': !$vuetify.breakpoint.md &&
                  !$vuetify.breakpoint.lg && !$vuetify.breakpoint.sm}">
                  {{formatedDate(item.updatedAt)}}</td>
                  <td class="text-end" width="10%">
                    <v-menu bottom left offset-y offset-x>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          height="auto"
                          width="auto"
                        >
                          <v-icon dense>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title>
                            <v-btn text class="w-100" @click="downloadFiles(item.id)">
                              <img
                                src="@/assets/svg/theme/download-small.svg"
                                class="download-icon"
                                width=24
                                height=14
                              />
                              Download
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="canUpdate && (item.order===null)">
                          <v-list-item-title>
                            <v-btn text class="w-100" @click="deleteFile(item.id)">
                              <v-icon class="delete-icon">mdi-delete-outline</v-icon>
                              Delete
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
                <Observer @intersect="intersected"/>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-if="loadingMore" class="load-more justify-center">
        Loading more...
      </v-row>
      <v-container
        v-if="previewModal"
      >
        <Modal
          persistent
          :modal="previewModal"
          content-class="asset-preview-modal full-screen"
          :max-width="$vuetify.breakpoint.smAndUp ? '1100' : '100%'"
          scrollable
        >
          <content-kit-file-preview
            :prevItemIndex="prevIndex"
            :nextItemIndex="nextIndex"
            :item="selectedItem"
            @close-modal="previewModal = false"
            @download="downloadFiles(selectedItem.id)"
            @onNavigate="navigate"
            :userRole="role"
            :src="src"
            :numPages="numPages"
            :pdfLoading="pdfLoading"
            :workSpaceId="selectedProjectId"
            :utilityId="utilityId"
            @onSaveAssets="$emit('onSaveAssets')"
          />
        </Modal>
      </v-container>
    </div>
    <div v-else>
      <v-col
        v-if="activeFilter"
        class="pt-0 file-padding noContent"
      >
      Sorry, no results found!
      Please try searching for something else or applying a different filter.
      </v-col>
      <v-col class="pt-0 pb-4 file-padding noContent" v-else>
        Uh oh. You don't have any files uploaded in this section
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import pdf from 'vue-pdf';
import Modal from '@/components/common/Modal';
import { getOptimizedS3ImageURL } from '@/helpers/';
import ContentKitFile from './ContentKitFile';
import ContentKitFilePreview from './ContentKitFilePreview';
import RemoveAssetConfirmationModel from './RemoveAssetConfirmationModel';
import Observer from './Observer';

export default {
  /* eslint-disable global-require */
  components: {
    ContentKitFile,
    ContentKitFilePreview,
    RemoveAssetConfirmationModel,
    Modal,
    Observer,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    dimensions: {
      type: Array,
      default: () => [],
    },
    socialTags: {
      type: Array,
      default: () => [],
    },
    favoriteType: {
      type: Boolean,
      default: false,
    },
    previewItems: {
      type: Array,
      default: () => [],
    },
    section: {
      type: String,
      required: true,
    },
    fileType: {
      type: String,
      default: 'photo',
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showItemsInGrid: {
      type: Boolean,
      default: true,
    },
    isProjectActive: {
      type: Boolean,
      default: false,
    },
    selectedProjectId: {
      type: Number,
      default: null,
    },
    utilityId: {
      default: 'brand',
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('project', ['currentProject']),
    ...mapGetters(['contentKitFilters']),
    ...mapGetters('contentKit', ['loadingMore', 'hasMore']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    customer() {
      return ['client', 'member', 'agency_owner', 'agency_member'].includes(this.role);
    },
    isClient() {
      return ['client', 'agency_owner'].includes(this.role);
    },
    canUpdate() {
      const team = _.get(this.currentProject, 'business.businessTeam', []);
      const assigned = !!team.find((x) => `${x.userId}` === `${this.userDetails.id}`);
      return ['executive', 'creative', 'producer'].includes(this.role)
        && assigned;
    },
    activeFilter() {
      return _.get(this.contentKitFilters, 'search', '').length > 2
        || _.get(this.contentKitFilters, 'selectedTypes', []).length > 0
        || _.get(this.contentKitFilters, 'selectedDimensions', []).length > 0
        || _.get(this.contentKitFilters, 'selectedPlatforms', []).length > 0;
    },
  },
  data() {
    return {
      shiftKey: false,
      selected: this.selectedItems,
      pdfLoading: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Owner', value: 'uploadedUser.firstName' },
        { text: 'Last Modified', value: 'updatedAt' },
        { text: '', value: '' },
      ],
      currentItemName: '',
      previewModal: false,
      selectedItem: {},
      prevIndex: null,
      nextIndex: null,
      src: '',
      numPages: 0,
    };
  },
  watch: {
    selectedItems() {
      this.selected = this.selectedItems;
    },
  },
  methods: {
    ...mapActions('contentKit', ['updateContentKitFile', 'downloadContentKitFiles', 'archiveContentKitFiles']),
    ...mapActions(['setDownloadItems']),
    getOptimizedS3ImageURL,
    isFileImage(format) {
      return format && ['jpg', 'png', 'jpeg', 'gif'].includes(format.split('/')[1]);
    },
    image(item) {
      return item.smallImage || item.file;
    },
    fileFormat(item) {
      if (_.split(item.fileFormat, '/')[1] === 'x-subrip') {
        return 'srt';
      }
      return _.split(item.fileFormat, '/')[1];
    },
    poster(item) {
      if (item.poster) {
        return item.poster;
      }
      if (this.fileFormat(item) === 'mp4') {
        return require('@/assets/svg/theme/mp4.svg');
      }
      return require('@/assets/svg/theme/attach.svg');
    },
    validImage(item) {
      return this.image(item) && this.isFileImage(item.fileFormat);
    },
    cardImage(item) {
      return this.validImage(item) ? this.image(item) : this.defaultImage;
    },
    defaultImage(item) {
      if (this.fileFormat(item) === 'ai') {
        return require('@/assets/svg/theme/ai.svg');
      }
      if (this.fileFormat(item) === 'psd') {
        return require('@/assets/svg/theme/psd.svg');
      }
      if (this.fileFormat(item) === 'srt') {
        return require('@/assets/svg/theme/srt.svg');
      }
      return require('@/assets/svg/theme/project-banner-default.svg');
    },
    isSelected(itemId) {
      return _.includes(this.selected, itemId);
    },
    onFileCheckChange(itemId, selectedIds) {
      const checked = _.includes(selectedIds, itemId);
      if (checked) {
        this.selected = _.without(this.selected, itemId);
        this.beforeSelectFile(itemId);
      } else {
        this.$emit('selected', this.selected, this.fileType);
      }
    },
    beforeSelectFile(itemId) {
      // check if shift key is pressed
      if (this.shiftKey) {
        this.selectFile(itemId);
      } else {
        this.toggleSelected(itemId);
      }
    },
    // this function is used to select multiple files with Shift key
    selectFile(itemId) {
      // if not selected, add to selected
      if (!this.isSelected(itemId)) {
        // find the index of the item
        const index = _.findIndex(this.items, { id: itemId });
        // find the last selected index before the current index
        let beforeIndex = -1;
        for (let i = index - 1; i >= 0; i -= 1) {
          if (this.isSelected(this.items[i].id)) {
            beforeIndex = i;
            break;
          }
        }
        // fine the first selected index after the current index
        let afterIndex = -1;
        for (let i = index + 1; i < this.items.length; i += 1) {
          if (this.isSelected(this.items[i].id)) {
            afterIndex = i;
            break;
          }
        }
        // if there are two sides selected, just select the current the one.
        if (beforeIndex !== -1 && afterIndex !== -1) {
          this.selected.push(itemId);
        } else if (beforeIndex !== -1) {
          // select all the items from beforeIndex to currentIndex
          for (let i = beforeIndex; i <= index; i += 1) {
            this.selected.push(this.items[i].id);
          }
        } else if (afterIndex !== -1) {
          // select all the items from currentIndex to afterIndex
          for (let i = index; i <= afterIndex; i += 1) {
            this.selected.push(this.items[i].id);
          }
        } else {
          // select the current item
          this.selected.push(itemId);
        }
      } else {
        this.selected = _.without(this.selected, itemId);
      }
      this.$emit('selected', this.selected, this.fileType);
    },
    isBusiness() {
      return this.page === 'business';
    },
    renameFile(file) {
      this.updateContentKitFile({
        contentKitId: this.$route.params.contentKitId,
        payload: {
          contentKitFileId: file.id,
          name: file.name,
          workspaceId: this.selectedProjectId,
        },
      });
    },
    intersected() {
      // load more content kit items with current filter configuration
      if (this.hasMore) {
        this.$emit('loadMore');
      }
    },
    updateFileName(file) {
      const nameWihoutExt = file.name.substr(0, file.name.lastIndexOf('.'));
      if (nameWihoutExt !== this.currentItemName) {
        const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
        this.renameFile({ id: file.id, name: `${this.currentItemName}.${ext}` });
      }
    },
    checkSelected(itemId) {
      return _.includes(this.selected, itemId);
    },
    toggleSelected(itemId) {
      if (!this.checkSelected(itemId)) {
        this.selected.push(itemId);
      } else {
        this.selected.splice(this.selected.indexOf(itemId), 1);
      }
      this.$emit('selected', this.selected, this.fileType);
    },
    formatedDate(timestamp) {
      return moment(timestamp).format('LL');
    },
    async downloadFiles(fileId) {
      const file = _.find(this.items, ['id', fileId]);
      const result = await this.downloadContentKitFiles({
        contentKitId: file.contentKitId,
        fileIds: [fileId],
        utilityId: this.utilityId,
      });
      if (result.success) {
        _.map(result.data, (fileObject) => {
          this.setDownloadItems({
            files: [fileObject],
            folderName: fileObject.fileName,
            completed: false,
            error: null,
          });
        });
      }
    },
    archiveFile(file) {
      this.archiveContentKitFiles({
        contentKitId: this.$route.params.contentKitId,
        payload: { contentKitFileIds: [file.id] },
      });
    },
    async deleteFile(file) {
      const confirmModal = this.$refs.removeAssetConfirmationModel;
      const { confirm } = await confirmModal.open();
      if (!confirm) return;
      await this.archiveContentKitFiles({
        contentKitId: file.contentKitId,
        payload: { contentKitFileIds: [file.id], deletePermanently: 1 },
        status: file.status,
      });
      this.$emit('getContent');
    },
    itemName(item) {
      return item.name.substr(0, item.name.lastIndexOf('.'));
    },
    setCurrentItemName(item) {
      this.currentItemName = this.itemName(item);
    },
    previewClick(id) {
      this.previewModal = true;
      const itemIndex = _.findIndex(this.previewItems, ['id', id]);
      this.selectedItem = this.previewItems[itemIndex];
      this.prevIndex = itemIndex - 1;
      this.nextIndex = itemIndex === this.previewItems.length - 1 ? -1 : itemIndex + 1;
      this.getPdfFullFileView(this.selectedItem);
    },
    navigate(index) {
      this.selectedItem = Object.assign({}, this.previewItems[index]);
      this.prevIndex = index - 1;
      this.nextIndex = index === this.previewItems.length - 1 ? -1 : index + 1;
      this.getPdfFullFileView(this.selectedItem);
    },
    getPdfFullFileView(selectedItem) {
      const ext = selectedItem.fileFormat;
      if (ext === 'application/pdf') {
        this.pdfLoading = true;
        const loadingTask = pdf.createLoadingTask(selectedItem.file);
        this.src = loadingTask;
        this.src.promise.then((pdfFile) => {
          this.numPages = pdfFile.numPages;
          this.pdfLoading = false;
        });
      }
    },
  },
  mounted() {
    // listen for shift key
    document.addEventListener('keydown', (e) => {
      this.shiftKey = e.shiftKey;
    });
    document.addEventListener('keyup', (e) => {
      this.shiftKey = e.shiftKey;
    });
  },
  beforeDestroy() {
    document.removeEventListener('keydown', () => {});
    document.removeEventListener('keyup', () => {});
  },
};
</script>


<style scoped lang="scss">
  .item-logo {
    width: 40px;
    position: relative;
  }
  .item-names {
    width: 150px !important;
    overflow-x: scroll;
  }
  .image-name {
    display: none;
  }
  .user-name {
    display: none;
  }
  .user-names {
    padding-left: 40px !important;
  }
  .noContent {
    justify-content: center;
    padding-top: 100px !important;
    font-size: 18px;
    font-family: $fontFamily1;
  }
  .file-padding {
    display: flex;
    flex-wrap: row;
    padding: 5px;
  }
  .content-kit-file-container {
    background: white;
    position: relative;
    width: 184px;
    border: solid 1px #edeff2 !important;
    &.active {
      // width: 190px !important;
      border: solid 1px $lightGreen !important;
    }
  }
  .pagecustomer {
    width: 184px;
  }
  @media (min-width: 1200px) {
    .content-kit-file-container {
      width: 190px;
    }
    .file-padding {
      padding: 2px;
    }
  }
  @media (min-width: 1900px) {
    .pagecustomer {
    width: 214px !important;
  }
  }
  .multi-check {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 2;
    ::v-deep {
      .v-icon{
        background-color: rgba(0, 0, 0, 0.2);
        width: 14px;
        height: 14px;
        border-radius: 50%;
        font-size: 18px;
        &.mdi-checkbox-blank-circle-outline{
        color: #fff !important;
        }
        &.mdi-checkbox-marked-circle{
          color: $lightGreen !important;
        }
      }
      .v-input--selection-controls__ripple {
        width: 18px;
        height: 18px;
      }
    }
    &.v-input--is-label-active {
      ::v-deep {
        .v-icon{
          background-color: white;
        }
      }
    }
  }
  .v-menu__content {
    border-radius: 0;
    margin-right: 10px;
  }
  .v-list-item {
    min-height: auto;
    padding: 0;
    .v-btn {
      height: 30px;
      :hover::before {
        opacity: 0.9 !important;
      }
    }
    ::v-deep {
      .v-btn__content {
        font-size: 14px;
        justify-content: flex-start;
        width: auto;
        .download-icon, .archive-icon {
          padding-right: 5px;
        }
      }
      .v-btn {
        padding-right: 50px;
      }
    }
  }
  .content-kit-play {
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 40%;
    .play-btn {
      background: rgba(0, 0, 0, 0.4);
      color: white;
      cursor:pointer;
    }
  }
  .v-data-table {
    background-color: inherit !important;
    ::v-deep th, td {
      color: black !important;
      font-family: $fontFamily1;
      font-size: 14px !important;
    }
    ::v-deep th {
      font-weight: normal;
      height: auto;
      letter-spacing: -0.11px;
      padding: 0 10px 5px 0;
    }
    ::v-deep td {
      padding: 0 10px 0 0;
    }
    td .item-name {
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
    tr.active {
      background: #E8EBF6 !important;
    }
  }
  @media (max-width: 600px) {
    .content-kit-file-container {
      &.active {
        width: 190px;
      }
    }
    .file-padding {
      padding-left: 4px;
    }
  }
  .load-more {
    width: 100%;
  }
</style>
