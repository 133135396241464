<template>
  <v-row class="h-100 ma-auto preview-container">
    <v-col
    cols="1"
    v-if="$vuetify.breakpoint.smAndUp" class="d-flex align-center justify-center pa-0">
      <v-btn
        icon
        x-large
        class="previous navigate-btn"
        @click="$emit('onNavigate', prevItemIndex)"
        :disabled="prevItemIndex < 0"
      >
        <v-icon class="left"> mdi-chevron-left </v-icon>
      </v-btn>
    </v-col>
    <v-col cols="10" md="9" class="content-kit-file-preview pa-0">
      <div class="preview-header">
        <v-row class="ma-auto heading">
          <v-col class="d-flex align-center pa-0" cols="11">
            <div class="file-name notranslate">{{ item.name }}</div>
          </v-col>
          <v-col class="d-flex justify-end py-0 pr-0" cols="1">
            <v-icon color="darken-1" class="close" @click="closeForm">mdi-close</v-icon>
          </v-col>
        </v-row>
      </div>
      <div class="item-body">
        <v-row class="ma-0 d-flex body-container">
          <v-col cols="12" lg="6" md="6" class="pa-0">
            <v-card class="d-flex align-center justify-center h-100 file-container" :key="item.id">
              <div v-if="$vuetify.breakpoint.xsOnly">
                <v-btn
                  icon
                  x-large
                  class="previous navigation-btn"
                  @click="$emit('onNavigate', prevItemIndex)"
                  :disabled="prevItemIndex < 0"
                >
                  <v-icon> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn
                  icon
                  x-large
                  class="next navigation-btn"
                  @click="$emit('onNavigate', nextItemIndex)"
                  :disabled="nextItemIndex < 0"
                >
                  <v-icon> mdi-chevron-right </v-icon>
                </v-btn>
              </div>
              <div class="content-kit-favourties" v-if="utilityId !== 'brand'">
                <template v-if="customer">
                  <v-icon class="favourtie" color="#8066FF" @click="togglefavoriteIcon(item.id)">
                    {{ item.favorite ? 'mdi-heart' : 'mdi-heart-outline' }}
                  </v-icon>
                </template>
                <template v-else-if="item.favorite">
                  <v-icon color="#8066FF"> mdi-heart </v-icon>
                </template>
              </div>
              <template v-if="item.fileType == '1'">
                <template v-if="validImage">
                  <v-img
                    contain
                    :key="item.file"
                    :src="getOptimizedS3ImageURL(item.file, { width: 600, height: 600 })"
                    :style="$vuetify.breakpoint.mdAndDown ? mobileImageStyles : imageStyles"
                    :lazy-src="item.smallImage"
                    class="image-file"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </template>
                <template v-else>
                  <img
                    contain
                    class="noimage-height"
                    :key="item.file"
                    :src="defaultImage"
                    alt="no-image"
                    :style="$vuetify.breakpoint.mdAndDown ? mobileImageStyles : imageStyles"
                  />
                </template>
              </template>
              <template v-else-if="item.fileType == '2'">
                <video-player :options="videoOptions" class="video-player" />
              </template>
              <template v-else>
                <div class="pdf-loading" v-if="pdfLoading">
                  <v-progress-circular indeterminate color="grey" />
                </div>
                <template v-if="fileFormat === 'pdf'">
                  <div class="pdf-container">
                    <pdf
                      class="pdf-page-container"
                      v-for="i in numPages"
                      :key="i"
                      :src="src"
                      :page="i"
                      style="height: 433px;"
                      type="application/pdf"
                    >
                    </pdf>
                  </div>
                </template>
                <template v-else>
                  <iframe
                    class="contentkit-pdf"
                    :src="`https://view.officeapps.live.com/op/embed.aspx?src=${item.file}`"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                    role="document"
                    width="100%"
                    height="433px"
                    :key="'contentkit-iframe-' + item.file"
                    frameborder="0"
                  >
                  </iframe>
                </template>
              </template>
            </v-card>
          </v-col>
          <v-col cols="12" lg="6" md="6" class="item-caption pl-6 pa-0">
            <template>
              <v-row
                class="mb-3 ma-auto flex-column align-start"
                v-if="item.fileType == '2'
                && (fileFormat === 'mp4' || fileFormat === 'mov')
                && !customer
                && utilityId !== 'brand'"
              >
                <label class="text-left font-label mr-3 mb-1"> Poster </label>
                <Upload
                  v-model="file"
                  mode="justImage"
                  @change="posterUpdate"
                  :acceptedFiles="'.jpg,.jpeg,.png,.gif'"
                >
                  <template v-slot:default="{ filePreview }">
                    <div class="d-flex ma-auto profile-image-container" :class="'border-none'">
                      <v-img
                        class="profile-image"
                        lazy-src="@/assets/svg/theme/profile.svg"
                        :src="image(filePreview)"
                        alt="Profile"
                      />
                      <div class="profile-image-loading" v-if="imageLoading">
                        <v-progress-circular indeterminate color="grey" />
                      </div>
                      <div class="profile-image-overlay">
                        <img src="@/assets/svg/camera_event.svg" />
                        <span>Update</span>
                      </div>
                    </div>
                  </template>
                </Upload>
              </v-row>
              <v-row class="pb-2 ma-auto"
               v-if="utilityId !== 'brand'">
                <v-col :cols="3" class="pa-0">
                  <label class="text-left font-label"> Dimensions </label>
                  <CustomComboBox
                    solo
                    dense
                    class="dimension-width"
                    :items="dimensions"
                    item-text="name"
                    item-value="id"
                    return-object
                    name="dimensions"
                    hide-details
                    :value="item.dimension"
                    :mode="editTags"
                    :notClearable="true"
                    @change="updateDimension"
                  >
                    <span class="content">{{ item.dimension ? item.dimension.name : '-' }} </span>
                  </CustomComboBox>
                </v-col>
              </v-row>
              <v-row class="py-2 ma-auto" v-if="utilityId !== 'brand'">
                <v-col class="pa-0">
                  <label class="text-left font-label"> Suggested Platforms </label>
                  <CustomComboBox
                    solo
                    dense
                    multiple
                    :items="socialTags"
                    item-text="name"
                    return-object
                    name="skillsets"
                    hide-details
                    :mode="editTags"
                    v-model="item.socialTags"
                    class="notranslate"
                  >
                    <span class="content">{{ socialTagNames ? socialTagNames : '-' }} </span>
                  </CustomComboBox>
                </v-col>
              </v-row>
            </template>
            <template v-if="utilityId !== 'brand'">
              <v-row class="py-2 ma-auto">
                <v-col class="pa-0">
                  <label class="text-left font-label"> Creatives </label>
                  <template v-if="canEditTags">
                    <v-select
                      :items="creatives"
                      item-value="id"
                      item-text="name"
                      multiple=""
                      solo
                      flat
                      v-model="userIds"
                      hide-details=""
                      class="creatives-selection notranslate"
                      :menu-props="{ 'content-class': 'creatives-dropdown notranslate' }"
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip small color="#e5e7fa" class="creative-chip" label>
                          <span class="notranslate">{{ item.name }}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </template>
                  <template v-else>
                    <div class="content notranslate">
                      {{ itemCreativeNames ? itemCreativeNames : '-' }}
                    </div>
                  </template>
                </v-col>
              </v-row>
              <template>
                <v-row class="py-2 ma-auto">
                  <v-col class="pa-0">
                    <label class="text-left font-label"> Categories </label>
                    <template v-if="canEditTags">
                      <v-select
                        :items="categories"
                        item-value="id"
                        item-text="name"
                        solo
                        flat
                        multiple=""
                        v-model="selectedFileCategory"
                        hide-details=""
                        class="creatives-selection notranslate"
                        :menu-props="{ 'content-class': 'creatives-dropdown notranslate' }"
                      >
                        <template v-slot:selection="{ item }">
                          <v-chip small color="#e5e7fa" class="creative-chip" label>
                            <span class="notranslate">{{ item.name }}</span>
                          </v-chip>
                        </template>
                      </v-select>
                    </template>
                    <template v-else>
                      <div class="content notranslate">
                        {{ selectedFileCategory && selectedFileCategory.length > 0
                        ? getCategories() : '-' }}
                      </div>
                    </template>
                  </v-col>
                </v-row>
              </template>
              <v-row class="py-2" v-if="item.captionNeeded">
                <v-col class="pa-0">
                  <label class="text-left font-label">
                    Caption
                    <v-btn color="primaryGray1" class="ml-2"
                      x-small
                      icon
                      v-clipboard:copy="item.caption"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      :disabled="!item.caption"
                    >
                      <v-icon dark> mdi-content-copy </v-icon>
                    </v-btn>
                  </label>
                  <base-text-area-field
                    :disabled="customer"
                    :rows="3"
                    name="caption"
                    v-model.trim="item.caption"
                    :mode="amIAssignedTOProject ? 'edit' : 'normal'"
                    class="caption-field"
                    hide-details
                    dense
                    solo
                  >
                    <span class="content">{{item.caption}}</span>
                  </base-text-area-field>
                </v-col>
              </v-row>
              <v-row class="py-2 ma-auto">
                <v-col class="pa-0">
                  <div class="align">
                    <label class="text-left font-label w-100"> Order Number </label>
                    <span class="content notranslate">
                      {{ item.order && item.order.id ? item.order.id : '-' }}
                    </span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="py-2 ma-auto">
                <v-col class="pa-0">
                  <div class="align">
                    <label class="text-left font-label w-100"> Order Details </label>
                    <span class="content notranslate deliverableName">
                      Deliverable: {{ selectedDeliverable ? selectedDeliverable : '-' }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </template>
            <v-btn text class="pa-0 pl-2 mt-4 downloadBtn" @click="$emit('download')">
              <span class="downloadText">Download Asset</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="footer" v-if="utilityId !== 'brand'">
        <div><v-divider> </v-divider></div>
        <div class="item-actions pa-5" v-if="canEditTags">
          <v-row>
            <v-col cols="12" class="d-flex justify-end py-0">
              <div>
                <v-btn text @click="closeForm" class="pa-0 cancel">
                  Cancel
                </v-btn>
              </div>
              <div>
                <v-btn color type="submit" class="pa-0 submit ml-3" @click="updateAsset">
                Save
              </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-col>
    <v-col
    cols="1"
    v-if="$vuetify.breakpoint.smAndUp" class="d-flex align-center justify-center pa-0">
      <v-btn
        icon
        x-large
        class="next navigate-btn"
        @click="$emit('onNavigate', nextItemIndex)"
        :disabled="nextItemIndex < 0"
      >
        <v-icon class="right"> mdi-chevron-right </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import linkify from 'vue-linkify';
import pdf from 'vue-pdf';
import _ from 'lodash';
import VideoPlayer from '@/components/common/VideoPlayer';
import CustomComboBox from '@/components/common/CustomComboBox';
import Upload from '@/components/common/Upload';
import { getOptimizedS3ImageURL } from '@/helpers/';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';

export default {
  /* eslint-disable global-require */
  components: {
    CustomComboBox,
    Upload,
    VideoPlayer,
    pdf,
    BaseTextAreaField,
  },
  directives: {
    linkified: linkify,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    userRole: {
      type: String,
      required: true,
    },
    prevItemIndex: {
      type: Number,
      default: 0,
    },
    nextItemIndex: {
      type: Number,
      default: 0,
    },
    src: {
      type: Object,
      required: true,
    },
    numPages: {
      type: Number,
      default: 0,
    },
    pdfLoading: {
      type: Boolean,
      default: false,
    },
    workSpaceId: {
      type: Number,
      default: null,
    },
    utilityId: {
      default: '',
    },
  },
  watch: {
    item(newVal) {
      this.userIds = _.map(newVal.users, 'id');
      this.selectedFileCategory = _.map(newVal.contentFileCategory, 'fileCategories.id');
      this.selectedDeliverable = _.get(newVal.orderDeliverable, 'deliverable.name');
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('project', ['producersTeam', 'creativeTeam', 'executiveTeam', 'allCreatives', 'currentProject']),
    ...mapGetters('contentKit', ['dimensions', 'socialTags']),
    ...mapGetters('business', ['categories']),
    customer() {
      return ['client', 'member', 'agency_owner', 'agency_member'].includes(this.userRole);
    },
    amIAssignedTOProject() {
      const team = _.get(this.currentProject, 'business.businessTeam', []);
      const assigned = !!team.find((x) => `${x.userId}` === `${this.userDetails.id}`);
      return assigned;
    },
    image() {
      return (filePreview) => {
        const profilePic = _.get(this.item, 'poster');
        if (filePreview) {
          return filePreview;
        }
        if (profilePic) {
          // this.setBorderVisibility();
          return profilePic;
        }
        return require('@/assets/svg/users.svg');
      };
    },
    socialTagNames() {
      return _.compact(_.map(this.item.socialTags, (tag) => tag.name.trim())).join(', ');
    },
    videoOptions() {
      return {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: this.item.file,
            type: 'video/mp4',
          },
        ],
        poster: this.poster,
      };
    },
    poster() {
      return this.item.poster;
    },
    isCreative() {
      return this.userRole === 'creative';
    },
    isExecutive() {
      return this.userRole === 'executive';
    },
    isProducer() {
      return this.userRole === 'producer';
    },
    isAdmin() {
      return this.userRole === 'admin';
    },
    roleChecking() {
      if (this.isExecutive || this.isProducer || this.customer) {
        return false;
      }
      return true;
    },
    canEditTags() {
      return this.isAdmin || this.isExecutive || (this.amIAssignedTOProject && (this.isProducer));
    },
    editTags() {
      return this.canEditTags ? 'edit' : 'normal';
    },
    isLandscape() {
      return this.width >= this.height;
    },
    fileFormat() {
      return _.split(this.item.fileFormat, '/')[1];
    },
    validImage() {
      return !_.includes(['ai', 'psd'], this.fileFormat);
    },
    defaultImage() {
      if (this.fileFormat === 'ai') {
        return require('@/assets/svg/theme/ai.svg');
      }
      if (this.fileFormat === 'psd') {
        return require('@/assets/svg/theme/psd.svg');
      }
      if (this.fileFormat === 'x-subrip') {
        return require('@/assets/svg/theme/srt.svg');
      }
      return require('@/assets/svg/theme/project-banner-default.svg');
    },
    imageStyles() {
      return this.isLandscape ? 'max-width: 100%; max-height: 433px;' : 'max-height: 433px;';
    },
    mobileImageStyles() {
      return this.isLandscape ? 'max-width: 100%;' : 'max-height: 433px;';
    },
    creatives() {
      return _.map(this.projectTeam, (creative) => ({
        id: creative.id,
        name: `${creative.firstName} ${creative.lastName}`,
      }));
    },
    projectTeam() {
      return [
        ..._.chain(this.producersTeam)
          .filter((producer) => producer.accepted === '1')
          .map((producer) => producer.user)
          .value(),
        ..._.chain(this.creativeTeam)
          .filter((creative) => creative.accepted === '1')
          .map((creative) => creative.user)
          .value(),
        ..._.chain(this.executiveTeam)
          .filter((executive) => executive.accepted === '1')
          .map((executive) => executive.user)
          .value(),
        ..._.chain(this.allCreatives),
      ];
    },
    itemCreativeNames() {
      return _.map(this.item.users, (c) => `${c.firstName} ${c.lastName}`).join(', ');
    },
    // notEdited() {
    //   const attrs = ['dimension', 'socialTags', 'caption', 'notes', 'userIds'];
    //   return _.isEqual(_.pick(this.itemBeforeEdit, attrs), _.pick(this.item, attrs));
    // },
  },
  data() {
    return {
      selectedDeliverable: '',
      userIds: _.map(this.item.users, 'id'),
      itemBeforeEdit: {},
      width: 200,
      height: 100,
      imageLoading: false,
      file: '',
      selectedFileCategory: [],
    };
  },
  mounted() {
    if (!this.customer) {
      this.getFileCategories();
      this.getSocialTags();
      this.getDimensions();
    }
    this.selectedFileCategory = _.map(this.item.contentFileCategory, 'fileCategories.id');
    this.selectedDeliverable = _.get(this.item.orderDeliverable, 'deliverable.name');
  },
  methods: {
    ...mapActions(['setNotification']),
    ...mapActions('contentKit', [
      'updateContentKitFile',
      'updateFavoriteContentKits',
      'unfavoriteContentKits',
      'uploadPoster',
      'getDimensions',
      'getSocialTags',
    ]),
    ...mapActions('business', ['getFileCategories']),
    getOptimizedS3ImageURL,
    getCategories() {
      const fileCategories = _.map(this.item.contentFileCategory, 'fileCategories');
      return _.compact(_.map(fileCategories, (tag) => tag.name.trim())).join(', ');
    },
    async updateAsset() {
      const result = await this.updateContentKitFile({
        id: this.item.id,
        payload: Object.assign(this.item, {
          categories: this.selectedFileCategory,
          contentKitFileId: this.item.id,
          userIds: this.userIds,
          workspaceId: this.workSpaceId,
        }),
      });
      if (result.success) {
        this.$emit('onSaveAssets');
        this.$emit('close-modal');
      }
    },
    onCopy() {
      this.setNotification(
        {
          message: 'Caption copied to clipboard.',
          type: 'success',
        },
        { root: true },
      );
    },
    onError(e) {
      this.setNotification(
        {
          message: e.message,
          type: 'error',
        },
        { root: true },
      );
    },
    updateDimension(value) {
      if (typeof value === 'string') {
        this.item.dimension = { name: value };
        this.getPlatforms(value);
      } else {
        this.item.dimension = value;
        this.getPlatforms(value.name);
      }
    },
    closeForm() {
      this.item = Object.assign(this.item, this.itemBeforeEdit);
      this.$emit('close-modal');
    },
    calculateDimensions() {
      [this.width, this.height] = this.item.dimensions.split('X').map((x) => +x);
    },
    async posterUpdate(file) {
      this.imageLoading = true;
      const result = await this.uploadPoster({ fileId: this.item.id, file });
      if (result.success) {
        this.item = Object.assign(this.item, { poster: result.contentKitFile.poster });
      }
      this.imageLoading = false;
    },
    async togglefavoriteIcon(id) {
      const workspaceId = _.get(this.userDetails, 'currentBusinessId');
      const result = this.item.favorite
        ? await this.unfavoriteContentKits({ id, workspaceId })
        : await this.updateFavoriteContentKits({ id, workspaceId });
      if (result.success) {
        this.item = Object.assign(this.item, { favorite: !this.item.favorite });
        this.$emit('favourite', this.item);
      }
    },
    getPlatforms(dimension) {
      switch (dimension) {
        case '1 x 1':
        case '4 x 5':
          this.item.socialTags = _.filter(this.socialTags, (tag) => _.includes(['Instagram', 'Facebook'], tag.name));
          break;
        case '9 x 16':
          this.item.socialTags = _.filter(this.socialTags, (tag) => _.includes(['Instagram Stories', 'Facebook Stories', 'Tik Tok'], tag.name));
          break;
        case '16 x 9':
          this.item.socialTags = _.filter(this.socialTags, (tag) => _.includes(['Facebook', 'LinkedIn', 'Instagram TV', 'YouTube', 'Website'], tag.name));
          break;
        case '4 x 6':
        case '6 x 4':
          this.item.socialTags = _.filter(this.socialTags, (tag) => _.includes(['Facebook'], tag.name));
          break;
        default:
          this.item.socialTags = [];
      }
    },
  },
  created() {
    this.itemBeforeEdit = Object.assign({}, this.item);
  },

  beforeMount() {
    if (this.item.fileType === '1') {
      this.calculateDimensions();
    }
  },
  beforeUpdate() {
    if (this.item.fileType === '1') {
      this.calculateDimensions();
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep.v-list-item__title{
  width:10px !important;
}
.navigate-btn {
  padding: 0;
  .v-btn__content .v-icon {
    color: #ffffff;
    font-size: 40px;
  }
}
.preview-container{
  min-width: 1190px !important;
  min-height: 600px;
}
.content-kit-file-preview {
  background: #ffffff;
  font-family: $fontFamily1;
  font-size: 14px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  .preview-header {
    padding: 16px 21px 13px 21px;
    border-bottom: 1px solid #d1d1d1;
    .file-name {
      word-break: break-all;
      font-family: $fontFamily1;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #929292;
    }
  }
  .item-body {
      overflow: auto;
      max-height: 550px;
      .body-container {
        padding: 28px 25px;
        padding-bottom: 15px !important;
      }
      .deliverableName {
        word-wrap: break-word;
      }
  }
  ::v-deep {
    .downloadBtn {
      background: #ffffff;
      border: 2px solid $secondary2;
      border-radius: 30px;
      padding: 5px 17px !important;
      .v-btn__content .downloadText {
        font-family: $fontFamily1;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #262626;
      }
    }
    .submit {
      background-color: $secondary1 !important;
      color: #ffffff;
      box-shadow: none !important;
    }
  }
}
.item-body {
  overflow: auto;
  position: relative;
  .dimension-width {
    width: 145px;
  }
  .content {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $titleBlack;
  }
  .file-container {
      background-color: $charcoalBlack;
      width: 572px;
      height: 433px !important;
    }
  .item-image {
    background: $neutral5;
    line-height: 0;
  }
  img {
    max-height: 433px;
  }
  .image-file {
    max-height: 100%;
  }
  .video-player {
    margin: 0 auto;
    height: 100%;
  }
  .combo-box {
    margin-bottom: 0px;
  }
  .v-chip.v-size--small {
    font-size: 13px;
  }
  .creative-chip {
    color: $primary2;
  }
  .creatives-selection {
    ::v-deep .v-input__control {
      min-height: 36px;
      border: solid 1px #e6e8ed;
    }
  }
}
.hint {
  font-style: italic;
}
::v-deep .v-input {
  height: auto !important;
  font-size: 14px;
}
::v-deep .v-input__slot {
  box-shadow: none !important;
}
::v-deep .theme--light.v-text-field--outlined > .v-input__control {
  border-radius: 10px;
  > .v-input__slot {
    background-color: white;
    padding: 0 16px;
    .v-label--active {
      top: 30px;
    }
    legend {
      width: 0 !important;
    }
    textarea {
      margin-top: 10px;
    }
  }
}
::v-deep .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 14px;
  padding-bottom: 4px;
  font-family: $fontFamily1;
  color: rgba(0, 0, 0, 0.87);
}
::v-deep .theme--light.v-text-field--outlined > .v-input__control > .v-input__slot:before {
  background: white;
  border: solid 1px #9e9e9e;
}

.video-player {
  width: 100%;
}

.item-actions {
  .v-btn {
    border-radius: 30px;
    ::v-deep .v-btn__content {
      font-size: 16px;
      font-family: $fontFamily1;
      font-weight: 600;
      letter-spacing: normal;
      line-height: 28px;
      padding: 5px 17px;
    }
  }
  ::v-deep .cancel .v-btn__content {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: $secondary3 !important;
  }
}
.content-kit-favourties {
  position: absolute;
  bottom: 30px;
  right: 10px;
  z-index: 2;
}
.star-outline {
  -webkit-text-stroke: 1.8px #f49c14;
  color: transparent !important;
  font-size: 20px !important;
}
.star-fill {
  color: #f4c40c;
  font-size: 24px;
}
.v-card:not(.v-card--flat):not(.v-picker--time):not(.v-picker--date) {
  border-radius: 0px !important;
}
.pdf-container {
  overflow-y: auto;
  height: 450px;
  .pdf-page-container {
    width: 100%;
    padding-bottom: 10px;
  }
}

.profile-image-container {
  width: 76px;
  height: 76px;
  cursor: pointer;
  position: relative;
}
.pdf-loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.profile-image {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
.profile-image-loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.profile-image-overlay {
  position: absolute;
  height: 40px;
  bottom: 0;
  background: $neutral5;
  display: none;
  width: 100%;
  border-radius: 0 0 5px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile-image-container:hover .profile-image-overlay {
  display: flex;
}
.noimage-height {
  height: 300px;
  padding: 50px 0;
}
.align {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 600.1px) and (max-width: 1264px) {
  .item-body {
    min-height: 220px;
  }
  .item-image {
    min-height: 250px;
  }
  .item-caption {
    padding-left: 15px !important;
    padding-top: 15px !important;
  }
  .contentkit-pdf {
    height: 400px;
  }
  .noimage-height {
    height: 200px;
    padding: 20px 0;
  }
}

@media screen and (max-width: 600px) {
  .navigation-btn {
    padding: 0;
    position: absolute;
    z-index: 2;
    height: 32px;
    width: 32px;
    background: $neutral5;
    &.previous {
      left: 0;
    }
    &.next {
      right: 0;
    }
  }
  .item-body {
    padding-left: 20px !important;
    padding-right: 20px !important;
    .video-player {
      height: 250px !important;
    }
    .noimage-height {
      height: 220px;
      padding: 40px 0;
    }
  }
  .item-image {
    min-height: 210px;
  }
  .item-caption {
    padding-left: 15px !important;
    padding-top: 15px !important;
  }
  .contentkit-pdf {
    height: 300px;
  }
}
</style>
