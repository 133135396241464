<!-- eslint-disable max-len -->
<template >
  <div class="selected-actions" :class="{'xs-screen':$vuetify.breakpoint.xs, 'small-screen':$vuetify.breakpoint.smOnly, 'medium-screen':$vuetify.breakpoint.mdAndUp}">
    <!-- <v-row v-if="this.$route.path === '/library/all_files'">
      <v-col class="d-flex justify-end"
       v-if="this.$route.path === '/library/all_files'">
        <v-btn text class="px-2 primary--text" @click="$emit('download')" color="info">
          <img src="@/assets/svg/theme/cloud-computing.svg" class="ml-2 mr-2" width=16 height=16/>
          Download
        </v-btn>
      </v-col>
    </v-row> -->
    <v-row class="flex-nowrap align-center actions">
      <v-col class="d-flex align-center" :class="{'mobile-screen': $vuetify.breakpoint.xsOnly}">
        <v-btn icon @click="$emit('removeSelection')">
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
        {{count}} Selected
      </v-col>
      <v-col cols=9 sm=9 class="justify-end" :class="{'py-0': $vuetify.breakpoint.xsOnly,'d-none': $vuetify.breakpoint.mdAndDown}">
      <v-btn text class="px-2 primary--text" @click="$emit('selectAll')" color="info" v-if="this.$route.name !== 'Assets'">
          Select all
        </v-btn>
        <v-btn text class="px-2 text-purple" @click="$emit('download')">
          <img src="@/assets/svg/download.svg" class="ml-2 mr-2" width=16 height=16/>
          Download
        </v-btn>
        <v-btn v-if="canUploadContentKits && utilityId!=='brand'" text class="px-2 text-purple" @click="$emit('bulkEdit')" :class="{'py-0': $vuetify.breakpoint.xsOnly,'d-none': $vuetify.breakpoint.mdAndDown}">
          <img src="@/assets/svg/edit2.svg" class="ml-2 mr-2" width=20 height=20/>Bulk Edit
        </v-btn>
        <v-btn text class="px-2 text-purple" @click="$emit('archive')"
          v-if="showArchive || isClient"
        >
          <img src="@/assets/svg/theme/trash.svg" class="ml-2 mr-2" width=16 height=16/> Delete
        </v-btn>
      </v-col>
      <v-col class="justify-end pa-0" :class="{'d-none': $vuetify.breakpoint.lgAndUp,
      'd-flex': $vuetify.breakpoint.mdAndDown}">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <!-- <v-btn
              v-bind="attrs"
              v-on="on" text class="px-2 primary--text">
                <img src="@/assets/svg/theme/move.svg" class="ml-2 mr-2" width=16 height=16/>
            </v-btn> -->
            <v-btn text class="px-2 primary--text" color="info"
              v-bind="attrs"
              v-on="on">
              <svg width="32" height="32" fill="black" viewBox="0 0 24 24">
                <path fill="black" d="M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z"/>
                <path fill="black" d="M13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z"/>
                <path fill="black" d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"/>
              </svg>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn text class="px-2 text-purple" @click="$emit('download')">
                <img src="@/assets/svg/download.svg" class="ml-2 mr-2" width=16 height=16/>
                Download
            </v-btn>
            </v-list-item>
            <v-list-item v-if="canUploadContentKits && utilityId!=='brand'">
                <v-btn text class="px-2 text-purple" @click="$emit('bulkEdit')" color="info">
                  <img src="@/assets/svg/edit2.svg" class="ml-2 mr-2" width=20 height=20/>Bulk Edit
                </v-btn>
              </v-list-item>
             <v-list-item v-if="showArchive">
              <v-btn text class="px-2 text-purple" @click="$emit('archive')">
               <img src="@/assets/svg/theme/trash.svg" class="ml-2 mr-2" width=16 height=16/> Delete
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
    showArchive: {
      type: Boolean,
      default: true,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
    filterType: {
      type: Number,
      required: true,
    },
    activeContentKit: {
      type: Boolean,
      required: true,
    },
    showReleased: {
      type: Boolean,
      default: false,
    },
    showAssigned: {
      type: Boolean,
      default: false,
    },
    canUploadContentKits: {
      type: Boolean,
      default: false,
    },
    utilityId: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('contentKit', ['currentContentKit']),
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    isClient() {
      return ['client', 'agency_owner'].includes(this.role);
    },
    activeFilter() {
      return this.filterType === 1;
    },
  },
};
</script>

<style lang="scss">
  .selected-actions {
    background: white;
    font-size: 16px;
    position: absolute;
    top: 0;
    z-index: 20;
    padding-top: 5px;
    display: flex;
    align-items: center;
    &.xs-screen{
      width: 80% !important;
      left: 30px !important;
    }
    &.small-screen {
      margin-top: 440px !important;
      width: calc(100% - 56px);
      width: 80%;
      left: 40px;
    }
    &.medium-screen{
      margin-top: -90px !important;
      width: 65%;
      height: 60px;
      width: calc(100% - 320px);
    }
  }
  @media (max-width: 1200px) {
    .selected-actions {
      width: 60% !important;
      height: 100px !important;
    }
  }
  @media (max-width: 960px) {
    .selected-actions {
      left: 0;
      padding-right: 10px;
      width: 85% !important;
      height: 65px !important;
      .v-col {
        padding-top: 0px;
        padding-bottom: 0;
      }
      .v-btn {
        padding: 0 !important;
        font-size: 12px;
      }
    }
}
@media (max-width: 600px) {
  .selected-actions {
    .v-btn {
      width: 25px !important;
    }
  }
}

@media (max-width: 600px) {
  .actions {
    margin-top: 10px;
  }
  .selected-actions {
    top:64px;
    height: 100px !important;
  }
  .mobile-screen {
    padding-bottom: 0px;
    padding-top: 0px;
    height: 20px;
  }
}
</style>
