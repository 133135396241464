<template>
  <div class="bg-white h-100">
    <div class="h-100"
    :class="$route.name === 'Asset Library' ? 'main-container' : 'pt-4'">
      <Loader v-if="loading" style="height:60vh;" />
      <div v-else>
        <v-col
        :class="$vuetify.breakpoint.mdAndUp ? 'pb-10' : 'pb-4'"
        v-if="$route.name === 'Asset Library'">
          <v-col> <PageTitle /> </v-col>
        </v-col>
        <UploadAssetModal
          v-if="showUploadAssetModal"
          :open="showUploadAssetModal"
          :isClient="isClient"
          orderId="null"
          :deliverable="{}"
          @updateContent="getContent"
          @close="onCloseUploadAssetsForm"
          page="asset_library"
          :selectedWorkspaceId="selectedWorkspaceId"
          :enableSharingWithBusinesses="showShared"
        />
        <v-col cols="12" class="d-flex pt-2">
          <v-row class="ma-auto">
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" xxl="3">
              <v-row class="dropdown ma-auto"
              v-if="page !== 'org_business' && workspaceList.length > 1">
                <div>
                  <v-select
                    class="notranslate filterMethods"
                    :items="workspaceList"
                    item-text="text"
                    item-value="value"
                    append-icon="keyboard_arrow_down"
                    v-model="selectedWorkspaceId"
                    solo
                    @change="onSwitchBrandProfile"
                    flat
                  >
                    <template class="test" v-slot:selection="{ item }">
                      <span> {{ item.text }} </span>
                      <p class="selected">{{ item.type }}</p>
                    </template>
                  </v-select>
                </div>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex pt-2">
                  <div class="heading1 font-label">Folders</div>
                </v-col>
                <div>
                  <div :class="addClass('brand')" @click="changeTab('brand')" class="font-h5">
                    Brand Files
                  </div>
                </div>
              </v-row>
              <v-row class="contentKits" v-if="contentKits.length">
                <v-col cols="12" class="d-flex pt-2">
                  <div class="contentKitHeading font-label">CATEGORIES</div>
                </v-col>
                <div
                  class="kits"
                  v-for="item in contentKits"
                  :key="`content-kit-${item.fileCategories
                  ? item.fileCategories.id : item.categoryId}`"
                >
                  <div
                  class="a-link"
                  :class="addClass(item.fileCategories ? item.fileCategories.id : item.categoryId)"
                  @click="changeTab(item.fileCategories ? item.fileCategories.id : item.categoryId)"
                  >
                    {{ item.fileCategories ? item.fileCategories.name : item.categoryId }}
                  </div>
                </div>
              </v-row>
              <v-row class="contentKits" v-if="canUploadContentKits || isClient">
                <v-col cols="12" class="d-flex pt-2">
                  <div class="contentKitHeading font-label">Actions</div>
                </v-col>
                <div>
                  <div :class="addClass('upload')" @click="showUploadAssetModal = true">
                    <img
                      src="@/assets/svg/upload-thick.svg"
                      class="download-icon"
                      width="24"
                      height="14"
                    />
                    Upload
                  </div>
                </div>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9" xxl="9">
              <v-row class="assets ma-auto pt-2">
                <v-col cols="12">
                  <ContentKitAssets
                    :selectedProjectId="selectedWorkspaceId"
                    :utilityId="selectedTab"
                    :currentTab="currentTab"
                    reference="media_library"
                    :showShared="showShared"
                    :canUploadContentKits="canUploadContentKits || isClient"
                    @onSaveAssets="onSaveAssets"
                    @getContent="getContent"
                    :page="page"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import ContentKitAssets from '@/views/ContentKitAssets';
import UploadAssetModal from '@/components/orders/UploadAssetModal';
import PageTitle from '@/components/common/PageTitle';
import Loader from '@/components/common/Loader';

export default {
  name: 'AssetLibrary',
  components: {
    ContentKitAssets,
    UploadAssetModal,
    PageTitle,
    Loader,
  },
  props: {
    page: {
      default: 'business',
    },
    currentTab: {
      default: false,
    },
  },
  watch: {
    currentTab(newVal) {
      if ([1, 3, 4].includes(newVal)) {
        this.getContent();
        if (this.contentKits.length) {
          this.selectedTab = 'All Assets';
        }
      }
    },
  },
  methods: {
    ...mapActions('business', [
      'getBusiness',
      'loadBusinessWorkspaces',
      'checkBusinessHasContentKits',
      'getWorkspaceContentKits',
    ]),
    ...mapActions('contentKit', ['checkUploadContentKit', 'getContentKitFiles']),
    ...mapActions(['setFilters', 'resetFilters']),
    onSaveAssets() {
      this.getContent();
    },
    onOpenUploadAssetsForm() {
      this.showUploadAssetModal = true;
    },
    async getContent() {
      let business = this.$route.query.businessId
        || this.$route.params.organizationId
        || this.$route.params.businessId;
      if (this.page === 'business') {
        business = this.$route.query.businessId;
      }
      if (this.page === 'organization') {
        business = this.$route.params.organizationId;
      }
      if (this.page === 'org_business') {
        business = this.$route.params.businessId;
      }
      if (this.page === 'org_library') {
        business = this.$route.params.organizationId;
      }
      this.getBusiness(business);
      const { projects, entityType } = await this.loadBusinessWorkspaces({ businessId: business });
      const workspaceList = _.map(projects, (project) => {
        let displayName = null;
        if (entityType === 'organization') {
          displayName = project.name;
        } else {
          displayName = project.business.name;
        }
        return {
          // eslint-disable-next-line
          text: `${displayName}`,
          value: project.id,
          projectId: project.id,
          canAccess: project.isAssigned,
          type: this.showShared && project.sharedWithBusinessId ? 'Business' : project.type,
        };
      });
      this.workspaceList = workspaceList;
      if (this.page === 'org_business') {
        // eslint-disable-next-line
        const businessWorkspace = projects.filter(
          // eslint-disable-next-line
          (x) => x.businessId == this.$route.params.organizationId && x.sharedWithBusinessId == business,
        );
        this.selectedWorkspaceId = _.get(businessWorkspace, '[0]').id;
      } else {
        this.selectedWorkspaceId = _.get(workspaceList, '[0]').value;
      }
      if (this.selectedWorkspaceId) {
        // eslint-disable-next-line
        this.contentKits = await this.getWorkspaceContentKits({
          workspaceId: this.selectedWorkspaceId,
        });
        this.canUploadContentKits = await this.checkUploadContentKit({
          projectId: this.selectedWorkspaceId,
        });
        await this.getContentKitFiles({
          workspaceId: this.selectedWorkspaceId,
          utilityId: this.selectedTab,
          // eslint-disable-next-line
          showShared: this.showShared,
          page: this.page,
          limit: 20,
        });
      }
    },
    async onCloseUploadAssetsForm() {
      this.currentUploadAssetsInfo = {};
      this.showUploadAssetModal = false;
      await this.getContentKitFiles({
        workspaceId: this.selectedWorkspaceId,
        utilityId: this.selectedTab,
        // eslint-disable-next-line
        showShared: this.showShared,
        page: this.page,
        limit: 20,
      });
    },
    async onSwitchBrandProfile(data) {
      this.resetFilters();
      // eslint-disable-next-line
      this.canUploadContentKits = await this.checkUploadContentKit({
        projectId: data,
      });
      this.contentKits = await this.getWorkspaceContentKits({ workspaceId: data });
      if (this.contentKits.length) {
        this.selectedTab = 'All Assets';
      }
    },
    getData(item) {
      return `${item.text}(${item.type})`;
    },
    addClass(tab) {
      if (this.selectedTab === tab) {
        return 'activeTab ml-5';
      }
      return 'heading2 text-purple ml-5';
    },
    changeTab(tab) {
      this.selectedTab = tab;
      this.resetFilters();
    },
  },
  data() {
    return {
      workspaceList: [],
      selectedTab: 'All Assets',
      selectedWorkspaceId: null,
      contentKits: [],
      showUploadAssetModal: false,
      currentUploadAssetsInfo: {},
      canUploadContentKits: false,
      loading: true,
      searchText: '',
    };
  },
  computed: {
    ...mapGetters('business', ['business']),
    ...mapGetters('contentKit', ['contentKitFiles']),
    ...mapGetters('user', ['userDetails']),
    showShared() {
      const type = _.get(this.business, 'type', '1');
      // eslint-disable-next-line
      return type == '2';
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    isClient() {
      return ['client', 'agency_owner'].includes(this.role);
    },
  },
  async mounted() {
    this.loading = true;
    await this.getContent();
    if (this.contentKits.length) {
      this.selectedTab = 'All Assets';
    } else {
      this.selectedTab = 'brand';
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  width: 100%;
  padding-bottom: 5%;
  ::v-deep .v-select__selections {
    display: block;
    height: 25px;
  }
}
@media (min-width: 1399px) {
  .filterMethods {
    background: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    height: 60px;
    width: 274px;
  }
  .selected {
    font-size: 13px;
    color: #bdbdbd;
  }
}
@media (max-width: 1399px) {
  .filterMethods {
    background: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    height: 60px;
    width: 178px;
  }
}
@media (max-width: 1000px) {
  .filterMethods {
    background: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    height: 60px;
    width: 90%;
    font-size: 10px;
  }
  .selected {
    font-size: 8px;
    color: #bdbdbd;
  }
}
.folder-header {
  border-bottom: 1px solid #d8d8d8;
  width: 80%;
}
.assets {
  background: #f7f7f7;
  border: 1px solid #eaeaea;
  border-radius: 2px;
  width: 100%;
  min-height: 500px;
}
.heading1 {
  // font-size: 18px;
  // font-family: $fontFamily1;
  // font-weight: 700 !important;
  // color: #979797;
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
  padding-bottom: 10px;
}
.heading2 {
  font-size: 16px;
  cursor: pointer;
  margin-left: 22%;
  width: 100%;
  font-family: $fontFamily1;
}
.activeTab {
  font-family: $fontFamily1;
  font-weight: 600;
  color: $charcoalBlack;
}
.brandkit {
  padding-top: 25px;
}
.contentKits {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
}
.selected {
  font-size: 13px;
  color: #bdbdbd;
}
.kits {
  display: flex;
  flex-direction: column;
}
</style>
