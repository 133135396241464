<template>
  <v-container fluid class="content-wrapper"
  :class="{'pa-0' : reference === 'media_library'}">
    <ContentKitFilters
    :showSearch="canShowSearch"
    :projectId="selectedProjectId"
    :utilityId="utilityId"
    > </ContentKitFilters>
    <div class="pt-5">
    <ContentKitOverview
      :isLoading="loading"
      :selectedProjectId="selectedProjectId"
      @filters-change="filtersValue"
      :utilityId="utilityId"
      :canUploadContentKits="canUploadContentKits"
      @onSaveAssets="handleAssets"
      @getContent="$emit('getContent')"
      @loadMore="loadMore"
      :page="page"
    > </ContentKitOverview>
    </div>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import ContentKitFilters from '../components/AssetLibrary/ContentKitFilters';
import ContentKitOverview from '../components/AssetLibrary/ContentKitOverview';

export default {
  components: {
    ContentKitFilters,
    ContentKitOverview,
  },
  props: {
    reference: {
      type: String,
      default: '',
    },
    selectedProjectId: {
      default: null,
    },
    utilityId: {
      default: 'brand',
    },
    showShared: {
      default: false,
    },
    page: {
      default: null,
    },
    currentTab: {
      default: false,
    },
    canUploadContentKits: {
      default: false,
    },
  },
  computed: {
    ...mapGetters('project', ['userProjects']),
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('contentKit', ['contentKitFiles']),
    ...mapGetters(['contentKitFilters']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    customer() {
      return ['client', 'member'].includes(this.role);
    },
    projectId() {
      return _.get(this.userProjects, '[0].id', null);
    },
    currentProjectId() {
      return this.$route.params.projectId
        || this.$route.query.projectId
        || this.selectedProjectId;
    },
    contentKitId() {
      return this.$route.params.contentKitId || this.$route.query.contentKitId;
    },
  },
  data() {
    return {
      loading: false,
      canShowSearch: false,
    };
  },
  methods: {
    ...mapActions('contentKit', ['getContentKitFiles', 'getDimensions', 'getSocialTags',
      'getUserContentKits', 'getCurrentKit', 'setLoadingMore']),
    ...mapActions('project', ['getProject', 'currentProject']),
    ...mapActions(['setFilters', 'resetFilters']),
    async handleAssets() {
      this.$emit('onSaveAssets');
      this.loading = true;
      await this.getContentKitFiles({
        workspaceId: this.selectedProjectId,
        utilityId: this.utilityId,
        // eslint-disable-next-line
        showShared: this.showShared,
        page: this.page,
        limit: 20,
      });
      this.loading = false;
    },
    // eslint-disable-next-line
    reloadAssets: _.debounce(async function () {
      const search = _.get(this.contentKitFilters, 'search', '') || '';
      const fileTypes = _.get(this.contentKitFilters, 'selectedTypes', []).map((typeId) => {
        if (typeId === '1') {
          return 'Photo';
        }
        if (typeId === '2') {
          return 'Video';
        }
        return 'Document';
      });
      const selectedPlatforms = _.get(this.contentKitFilters, 'selectedPlatforms', []);
      const favoriteItems = _.get(this.contentKitFilters, 'favoriteItems', false);
      const sortBy = _.get(this.contentKitFilters, 'sortBy', 'recent');
      this.loading = true;
      await this.getContentKitFiles({
        workspaceId: this.selectedProjectId,
        utilityId: this.utilityId,
        // eslint-disable-next-line
        showShared: this.showShared,
        limit: 20,
        fileTypes,
        searchText: search,
        selectedPlatforms,
        favorite: favoriteItems,
        sortBy,
        page: this.page,
        // add filters here
      });
      this.loading = false;
    }),
    async loadMore() {
      this.setLoadingMore(true);
      const search = _.get(this.contentKitFilters, 'search', '') || '';
      const fileTypes = _.get(this.contentKitFilters, 'selectedTypes', []).map((typeId) => {
        if (typeId === '1') {
          return 'Photo';
        }
        if (typeId === '2') {
          return 'Video';
        }
        return 'Document';
      });
      const selectedPlatforms = _.get(this.contentKitFilters, 'selectedPlatforms', []);
      const favoriteItems = _.get(this.contentKitFilters, 'favoriteItems', false);
      const sortBy = _.get(this.contentKitFilters, 'sortBy', 'recent');
      this.loadingMore = true;
      await this.getContentKitFiles({
        workspaceId: this.selectedProjectId,
        utilityId: this.utilityId,
        // eslint-disable-next-line
        showShared: this.showShared,
        skip: this.contentKitFiles.length,
        limit: 20,
        append: true,
        fileTypes,
        searchText: search,
        selectedPlatforms,
        favorite: favoriteItems,
        sortBy,
        // add filters here
      });
      this.loadingMore = false;
      this.setLoadingMore(false);
    },
    filtersValue(value) {
      this.canShowSearch = value > 0;
    },
  },
  watch: {
    // eslint-disable-next-line
    'contentKitFilters.selectedTypes'() {
      this.reloadAssets();
    },
    // eslint-disable-next-line
    'contentKitFilters.selectedPlatforms'() {
      this.reloadAssets();
    },
    // eslint-disable-next-line
    'contentKitFilters.favoriteItems'() {
      this.reloadAssets();
    },
    // eslint-disable-next-line
    'contentKitFilters.sortBy'() {
      this.reloadAssets();
    },
    // eslint-disable-next-line
    'contentKitFilters.search'() {
      this.reloadAssets();
    },
    async selectedProjectId(val) {
      this.loading = true;
      await this.getContentKitFiles({
        workspaceId: val,
        utilityId: this.utilityId,
        // eslint-disable-next-line
        showShared: this.showShared,
        page: this.page,
        limit: 20,
      });
      this.loading = false;
    },
    async utilityId(val) {
      this.loading = true;
      await this.getContentKitFiles({
        workspaceId: this.selectedProjectId,
        utilityId: val,
        // eslint-disable-next-line
        showShared: this.showShared,
        page: this.page,
        limit: 20,
      });
      this.loading = false;
    },
    async currentTab(newVal) {
      if (newVal === 3) {
        this.loading = true;
        await this.getContentKitFiles({
          workspaceId: this.selectedProjectId,
          utilityId: this.utilityId,
          // eslint-disable-next-line
          showShared: this.showShared,
          page: this.page,
          limit: 20,
        });
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.loading = true;
    this.getDimensions();
    this.getSocialTags();
    this.getContentKitFiles({
      workspaceId: this.selectedProjectId,
      utilityId: this.utilityId,
      // eslint-disable-next-line
      showShared: this.showShared,
      page: this.page,
      limit: 20,
    });
    // await this.getUserContentKits({ projectId, status: this.status });
    // const currentKit = this.getCurrentKit(contentKitId);
    // if (!currentKit) {
    //   this.$router.push({ path: '/dashboard' });
    // }
    this.loading = false;
  },
  beforeDestroy() {
    this.resetFilters();
    this.setLoadingMore(false);
  },
};
</script>

<style scoped lang="scss">
  @media (max-width: 600px) {
    .content-wrapper {
      padding: 15px 15px 0;
      padding-top: 50px;
    }
  }
</style>
