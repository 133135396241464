<template>
    <v-row class="selected-file-container ma-auto">
    <v-col cols="6">
        <div class="left-container">
            <div class="file-preview">
                <img
                    :src="file.preview.src"
                    v-if="file.preview.src && ['image', 'video'].includes(file.preview.type)"
                />
                <!-- show file icon -->
                <v-icon v-else size="68" color="#f2f2f2">
                    mdi-file-outline
                </v-icon>
            </div>
            <div class="metadata">
                <div class="file-name">
                    <v-text-field
                        v-model="file.name"
                        placeholder="File Name"
                        outlined
                        dense
                        :hide-details="!Boolean(error)"
                        class="file-name-input"
                        :error-messages="error"
                    ></v-text-field>
                </div>
                <div class="d-flex align-center flex-wrap">
                    <!-- tags -->
                    <div
                        :key="tag"
                        class="tag"
                        v-for="tag in file.suggestedPlatforms"
                    >
                        {{tag}}
                    </div>

                    <template>
                        <div class="text-center">
                            <v-menu
                                v-if="unselectedSocialTags.length > 0"
                                offset-x
                                offset-y
                            >
                            <template v-slot:activator="{ on, attrs }">
                            <div
                                v-bind="attrs"
                                v-on="on"
                                class="add-tag-icon"
                            >+</div>
                            </template>
                            <v-list>
                                <v-list-item
                                v-for="(item) in unselectedSocialTags"
                                :key="item.name"
                                @click="appendTag(item)"
                                >
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                            </v-menu>
                        </div>
                        </template>
                </div>
            </div>
        </div>
    </v-col>
    <v-col cols="6">
        <div class="selected-file-size d-flex justify-space-between">
            <div>Size:
                <span class="text-gray">
                    {{ file.formattedSize }}
                </span>
            </div>
            <div class="cross-mark" @click="$emit('remove')">
                <v-icon
                class="close cursor-pointer">mdi-close</v-icon>
            </div>
        </div>
        <div class="selected-file-size">Dimensions:
            <span
                v-if="['image', 'video'].includes(file.preview.type)"
                class="text-gray"
            >
                {{ dimensions }}
            </span>
            <span
                v-else
                class="text-gray"
            >
                Not Available
            </span>
        </div>
    </v-col>
</v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UploadFileLineItem',
  props: {
    file: {
      type: Object,
      required: true,
    },
    fileNameErrors: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('contentKit', ['socialTags']),
    unselectedSocialTags() {
      const selectedItems = this.file.suggestedPlatforms;
      return this.socialTags.filter((tag) => !selectedItems.includes(tag.name));
    },
    error() {
      return this.fileNameErrors && this.fileNameErrors[this.file.id];
    },
    dimensions() {
      const { width, height } = this.file.preview;
      if (width && height) {
        return `${width} X ${height}`;
      }
      return 'Not Available';
    },
  },
  watch: {
    // handle file name change
    // eslint-disable-next-line
    'file.name' (fileName) {
      const { id } = this.file;
      this.fileNameErrors = {
        ...this.fileNameErrors,
      };
      if (!fileName.match(/^[a-zA-Z0-9._ -]*$/)) {
        // eslint-disable-next-line
        this.fileNameErrors[id] = 'File names can only contain letters, numbers, -, _ and .';
      } else if (fileName.length > 50) {
        // eslint-disable-next-line
        this.fileNameErrors[id] = 'File names cannot be longer than 50 characters';
      } else {
        delete this.fileNameErrors[id];
      }
    },
  },
  methods: {
    onFileNameChange(value) {
      this.file.name = value;
    },
    appendTag({ name }) {
      this.file.suggestedPlatforms = [...new Set([...this.file.suggestedPlatforms, name])];
    },
  },
};
</script>

<style lang="scss" scoped>
  .selected-files-container {
    overflow: auto;
    max-height: 400px;
    border-bottom: 1px solid #E0E0E0;
    .selected-files-loop {
        border-bottom: 1px solid #E0E0E0;
        &:last-child {
            border: none;
        }
    }
  }
  .selected-file-size {
    color: #262626;
    .cross-mark .v-icon{
        color: #999999;
        width: 12px;
        height: 12px;
    }
    .text-gray {
        color: #999999;
    }
}


.left-container {
    display: flex;
    .metadata {
        flex: 1;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        ::v-deep .file-name .file-name-input .v-input__control .v-input__slot {
            min-height: 34px !important;
            input {
              color: #4F4F4F;
              font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            }
        }
        .tag {
            padding: 2px 10px;
            margin-top: 4px;
            margin-right: 4px;
            width: fit-content;
            background-color: #EEEEEE;
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #262626;
        }
        .add-tag-icon {
           width: 17px;
           height: 15px;
           background: #EEEEEE;
           border-radius: 3px;
           margin-top: 4px;
           display: flex;
           justify-content: center;
           align-items: center;
        }
    }
}
.file-preview {
    width: 68px;
    height: 68px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
    }
}
</style>
