<template>
  <div class="assetFilters">
    <v-row class="filterDiv ma-0">
      <v-col cols="12" lg="12" class="d-flex filters pt-2 search-padding flex-wrap">
       <div class="asset-view">
                <v-btn icon
                  @click="contentKitFilters.gridView = false"
                  :class="{'viewSelected': !contentKitFilters.gridView}"
                >
                <v-icon color='gray' >
                 list
                </v-icon>
                </v-btn>
                <v-btn icon
                  @click="contentKitFilters.gridView = true"
                  :class="{'viewSelected': contentKitFilters.gridView}"
                >
                  <v-img
                    src="@/assets/svg/grid.svg" contain width="20" height="15"
                  />
                </v-btn>
        </div>
        <div v-if="showSearch" class="search-input">
          <base-input
            class="search-input searchInput font-family-2"
            type="text"
            :clearable="true"
            solo
            flat
            dense
            sm
            hide-details
            prepend-inner-icon="search"
            placeholder="Search..."
            v-model="contentKitFilters.search"
            @change="filtersUpdated"
          />
        </div>
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <div v-if="showSearch" class="type-filter">
            <v-select
              :items="types"
              label="Type"
              solo
              flat
              dense
              item-text="name"
              item-value="id"
              :hide-details="true"
              append-icon="keyboard_arrow_down"
              v-model="contentKitFilters.selectedTypes"
              multiple=""
              :menu-props="{'content-class' : 'filter-dropdown', bottom: true, offsetY: true}"
              class="platform-filter"
              background-color="transparent"
              @change="filtersUpdated"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="selected"> Type </span>
              </template>
            </v-select>
          </div>
          <div v-if="showSearch && !isBrandTab" class="platform-filter">
            <v-select
              :items="socialTags"
              label="Platform"
              solo
              flat
              dense
              item-value="id"
              item-text="name"
              :hide-details="true"
              append-icon="keyboard_arrow_down"
              v-model="contentKitFilters.selectedPlatforms"
              @change="filtersUpdated"
              multiple=""
              :menu-props="{'content-class' : 'filter-dropdown', bottom: true, offsetY: true}"
              class="platform-filter"
              background-color="transparent"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="selected"> Platform </span>
              </template>
            </v-select>
          </div>
           <v-btn v-if="showSearch && !isBrandTab"  depressed min-width="40"
              class="heart-icon fav-btn ml-1 px-2"
              :class="{'viewSelected': contentKitFilters.favoriteItems}"
              @click="toggleFavorites"
              @change="filtersUpdated"
            >
              <v-icon>
                mdi-heart-outline
              </v-icon>
            </v-btn>
        </template>
        <template v-else>
          <template class="filter-icon" v-if="showSearch">
          <v-btn icon v-if="anyFilter" @click="mobileFilters = !mobileFilters" class="ml-auto mr-1">
            <v-img src="@/assets/svg/theme/filter-icon-selected.svg"
              contain width="20" height="20"/>
          </v-btn>
          <v-btn v-else icon @click="mobileFilters = !mobileFilters"
          class="ml-auto mr-1 filter-icons">
            <v-img src="@/assets/svg/theme/filter-icon.svg" contain width="20" height="20"/>
          </v-btn>
          </template>
        </template>

        <div class="ml-auto d-flex justify-end">
          <div class="d-none d-md-flex">
              <div class="sort-label pr-2 font-family-2">  Sort By: </div>
              <div class="sort-items">
                <v-select
                  :items="sortByItems"
                  solo
                  flat
                  dense
                  :hide-details="true"
                  v-model="contentKitFilters.sortBy"
                  item-value="value"
                  item-text="text"
                  append-icon="keyboard_arrow_down"
                  :menu-props="{'content-class' : 'filter-dropdown',
                    bottom: true, offsetY: true, flat: true}"
                  class="sort-dropdown"
                  background-color="transparent"
                >
                </v-select>
              </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-container
      fluid
      v-if="mobileFilters"
      background-color="white"
    >
    <Modal
      persistent
      fullscreen
      content-class="asset-filters-modal"
      :modal="mobileFilters"
      width="400"
      light
      background-color="white"
      >
        <div class="heading modal-header-title">
        <v-row class="ma-0 px-4 pb-2">
          <v-col class="d-flex align-center pb-0" cols="11">
            <div class="text-capitalize input-field-label"> Filters</div>
          </v-col>
          <v-col class="d-flex justify-end pb-0 px-0" cols="1">
            <v-icon color="darken-1" @click="closeFilters">mdi-close</v-icon>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12" class="pa-0"> <v-divider> </v-divider> </v-col>
        </v-row>
        </div>
        <div class="filters-list mt-2">
          <v-row class="ma-0 px-4 pb-2" v-if="kitView">
            <v-col class="pt-0" cols="12">
              <div class="sort-title"> Sort By </div>
              <v-radio-group
                v-model="mobileSelectedFilters.sortBy"
                row
                hide-details
              >
                <v-radio
                  v-for="item in sortByItems"
                  :key="item.text"
                  :label="item.text"
                  :value="item.value"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="py-0"> <v-divider> </v-divider> </v-col>
          </v-row>
          <v-row class="ma-0 px-4 pb-2">
            <v-col class="py-0" cols="12">
              <CustomCheckboxGroup
                label="Type"
                :options="types"
                name="contentTypeIds"
                item-text="name"
                item-value="id"
                v-model="mobileSelectedFilters.selectedTypes"
                hide-details
              >
              </CustomCheckboxGroup>
            </v-col>
            <v-col cols="12" class="py-0"> <v-divider> </v-divider> </v-col>
          </v-row>
          <v-row class="ma-0 px-4 pb-2" v-if="showSearch && !isBrandTab">
            <v-col class="py-0" cols="12">
              <CustomCheckboxGroup
                label="Platform"
                :options="socialTags"
                name="selectedPlatforms"
                item-text="name"
                item-value="id"
                v-model="mobileSelectedFilters.selectedPlatforms"
                hide-details
              >
              </CustomCheckboxGroup>
            </v-col>
          </v-row>
          <div class="actions">
            <v-divider> </v-divider>
            <v-row class="pr-4 pb-0 ma-0">
              <v-col cols="6" class="d-flex align-items-center">
                <v-btn small @click="resetMobileFilters" text> Clear All </v-btn>
              </v-col>
              <v-col class="d-flex justify-end px-0" cols="6">
                <v-btn small class="primary" @click="applyFilters">Apply</v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </Modal>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import axios from 'axios';
import EventBus from '@/helpers/event-bus';
import BaseInput from '@/components/common/BaseInput';
import CustomCheckboxGroup from '@/components/common/CustomCheckboxGroup';
import Modal from '@/components/common/Modal';

export default {
  components: {
    BaseInput,
    CustomCheckboxGroup,
    Modal,
  },
  props: {
    canAddKit: {
      type: Boolean,
      default: false,
    },
    projectId: {
      default: null,
    },
    utilityId: {
      default: 'brand',
    },
  },
  computed: {
    ...mapGetters('contentKit', ['currentContentKit', 'dimensions', 'socialTags']),
    ...mapGetters('project', ['currentProject']),
    ...mapGetters(['contentKitFilters']),
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    customer() {
      return ['client', 'member'].includes(this.role);
    },
    anyFilter() {
      const search = _.get(this.contentKitFilters, 'search', '') || '';
      return search.length > 2
        || _.get(this.contentKitFilters, 'selectedTypes', []).length > 0
        || _.get(this.contentKitFilters, 'selectedDimensions', []).length > 0
        || _.get(this.contentKitFilters, 'selectedPlatforms', []).length > 0;
      // || _.get(this.contentKitFilters, 'favoriteItems')
    },
    isActiveFilter(data) {
      if (this.contentKitFilters.gridView === data) {
        return 'filterActive';
      }
      return 'filter';
    },
    kitView() {
      return true;
    },
    canUploadAssets() {
      return this.canUploadContentKits && this.currentContentKit.id;
    },
    isBrandTab() {
      return ['brand', 'inspiration'].includes(this.utilityId);
    },
    isProjectActive() {
      return this.currentProject.status === '1';
    },
  },
  data() {
    return {
      filterItems: [{ text: 'Active', value: '1' }, { text: 'Archived', value: '2' }],
      contentKitType: 1,
      types: [{ name: 'Photo', id: '1' }, { name: 'Video', id: '2' }, { name: 'Document', id: '3' }],
      sortBy: 'File Name',
      sortByItems: [{ text: 'File Name', value: 'name' }, { text: 'Most Recent', value: 'recent' }],
      mobileFilters: false,
      mobileSelectedFilters: {
        sortBy: 'recent',
        selectedTypes: [],
        selectedDimensions: [],
        selectedPlatforms: [],
      },
      showSearch: true,
      canUploadContentKits: false,
    };
  },
  methods: {
    ...mapActions('contentKit', ['getDimensions', 'getSocialTags', 'checkUploadContentKit']),
    ...mapActions(['setFilters', 'resetFilters', 'setUploadingItems']),

    toggleFavorites() {
      this.contentKitFilters.favoriteItems = !this.contentKitFilters.favoriteItems;
      this.filtersUpdated();
    },
    filtersUpdated() {
      EventBus.$emit('filters-updated');
      this.contentKitFilters.search = _.get(this.contentKitFilters, 'search', '') || '';
      this.setFilters(this.contentKitFilters);
    },
    uploadContentKits(files) {
      if (files && files.length) {
        const contentKitId = this.$route.params.contentKitId || this.$route.query.contentKitId;
        _.forEach(files, (file) => {
          const filedata = file;
          const { CancelToken } = axios;
          const source = CancelToken.source();
          filedata.cancelSource = source;
          this.setUploadingItems(
            Object.assign({}, { file: filedata }, { contentKitId, completed: false, error: null }),
          );
        });
      }
    },
    applyFilters() {
      this.setFilters(Object.assign(this.contentKitFilters, this.mobileSelectedFilters));
      this.mobileFilters = false;
    },
    closeFilters() {
      this.mobileSelectedFilters = { ...this.contentKitFilters };
      this.mobileFilters = false;
    },
    resetMobileFilters() {
      this.mobileSelectedFilters = {
        sortBy: 'name',
        selectedTypes: [],
        selectedDimensions: [],
        selectedPlatforms: [],
      };
    },
    clearFilters() {
      this.resetFilters({ favoriteItems: this.contentKitFilters.favoriteItems });
    },
  },
  async mounted() {
    this.getDimensions();
    this.getSocialTags();
    const projectId = this.projectId || this.currentProject.id;
    if (projectId) {
      this.canUploadContentKits = await this.checkUploadContentKit({
        projectId,
      });
    }
  },
};
</script>

<style scoped lang="scss">
 .filterActive{
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background-color: white;
 }
 .asset-view {
   margin-right: 5px;
 }
  .filterDiv {
    border-bottom: 1px solid #E0E0E0;
  }
  .filters, ::v-deep .v-input, ::v-deep .v-label {
    color: black;
    font-family: $fontFamily1;
    font-size: 13px;
    font-weight: 500;
  }
  ::v-deep .theme--light.v-select .v-select__selection--comma {
    color: black;
  }
  .selected {
    color: $secondary1;
  }
  ::v-deep .input-field {
    height: 40px;
  }
  ::v-deep .v-input__slot {
    padding-right: 0 !important;
    padding-left: 8px !important;
  }
  ::v-deep .search-input .v-input__control .v-input__slot {
    border: 1px solid $border2;
    padding-right: 0;
  }
  .type-filter {
    width: 70px;
  }
  .dimension-filter {
    width: 98px;
  }
  .platform-filter {
    width: 95px;
  }
  .sort-label {
    font-size: 13px;
    margin-top: 10px;
  }
  .clear-filter {
    ::v-deep .v-btn__content {
      font-size: 13px;
    }
  }
  .sort-items {
    .sort-dropdown {
      ::v-deep .v-input__slot, ::v-deep .v-input__append-inner{
        padding-left: 2px !important;
      }
      ::v-deep .v-select__selections input {
        display: none;
      }
      ::v-deep .v-select__selection--comma {
        overflow: auto;
        margin: 0;
        min-width: min-content;
      }
    }
  }

  .favorite-selected {
    background-color: $secondary1 !important;
  }
  .viewSelected {
   background-color: #FFFFFF !important;
   border-radius: 5px;
  }

  .files-filter {
    width: 110px;
    .v-select {
      border-radius: 6px;
    }
  }
  ::v-deep .upload-button {
    margin-top: 5px;
    width: 89px;
    height: 28px !important;
    border-radius: 19.5px;
    box-shadow: 0 2px 4px 0 rgba(127, 127, 127, 0.5);
    border: solid 2px $card-title;
    color: $card-title;
    label {
      display: flex;
      justify-content: center;
      cursor: pointer;
      background-color: white;
      border-radius: 19.5px;
    }
  }
  ::v-deep .input-field {
      border-radius: 28px !important;
      width: 275px !important;
      height: 38px !important;
    }
  .asset-filters-modal {
    background-color: white;
    .heading {
      background: white;
      position: fixed;
      z-index: 5;
    }
    .filters-list {
      overflow: hidden;
      position: absolute;
      padding: 50px 0;
      .v-input__slot {
        padding-left: 0 !important;
      }
      .input-field-label {
        margin-bottom: 10px;
      }
      .sort-title {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: bold;
        font-family: $fontFamily1;
      }
    }
    .actions {
      bottom: 0;
      position: fixed;
      background: white;
      width: 100%;
    }
  }
  @media (max-width: 600px) {
   .search-input {
     margin-top: 5px;
     width: 170px !important;
     border-radius: 50px;
     .v-text-field input {
       font-size: 16px;
      }
    }
    .filter-icons {
      margin-top: 10px;
    }
    .search-padding {
      padding-left: 4px;
    }
  }
  @media (min-width: 400px) {
    .assetFilters {
      max-width: 90% !important;
      padding-right: 0px;
      padding-left: 0px !important;
    }
  }
</style>
