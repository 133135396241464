<template>
  <div>
    <label class="text-left input-field-label font-label">
      {{ label }}
      <slot name="tooltip" />
    </label>
    <v-input
      v-bind="$attrs"
      v-on="$listeners"
    >
      <div :class="inputStatus" class="input-field-wrapper">
        <div
          v-for="(option, index) in options"
          :key="index"
          class="input-field"
          :class="{'input-field-active': (option[itemValue] === $attrs.value)}"
        >
          <v-checkbox
            :label="option[itemText]"
            :value="option[itemValue]"
            :input-value="values"
            multiple
            color="info"
            v-bind="$attrs"
            v-on="$listeners"
            hide-details
            class="checkbox-group"
            off-icon='mdi-checkbox'
            on-icon='mdi-check'
          ></v-checkbox>
          <v-icon
            v-if="!noErrorIcon"
            v-show="status === 'error'"
            color="error"
          >mdi-alert-circle-outline</v-icon>
        </div>
      </div>
    </v-input>
  </div>
</template>
<script>
export default {
  name: 'CustomCheckboxGroup',
  model: {
    prop: 'values',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'normal',
    },
    values: {
      type: Array,
      default() {
        return [];
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    noErrorIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputStatus() {
      return `${this.status}State`;
    },
    itemText() {
      if (this.$attrs['item-text']) {
        return this.$attrs['item-text'];
      }
      return 'label';
    },
    itemValue() {
      if (this.$attrs['item-value']) {
        return this.$attrs['item-value'];
      }
      return 'value';
    },
  },
};
</script>

<style lang="scss" scoped>
.input-field-label {
  display: block;
  margin-bottom: 20px;
}
::v-deep .v-input__slot {
  margin-bottom: 0;
}
::v-deep .input-field .v-input__control > .v-input__slot {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0;
}
::v-deep .v-label {
  font-family: $fontFamily1;
  font-size: 16px;
  color: $neutral1;
}
.checkbox-group {
    ::v-deep {
      .v-icon{
        border-radius: 6px;
        border: 2px solid #929292 !important;
        &.mdi-check{
          color: $neutral6 !important;
          background-color: $secondary1 !important;
          border: 2px solid $primary1 !important;
          font-size: 18px;
        }
      }
    }
}
</style>
