<template>
  <div v-if="mode==='edit'" class="base-input">
    <label class="text-left font-label">{{ inputLabel }}</label>
    <v-text-field
      class="input-field"
      :class="inputStatus"
      :append-icon="appendedIcon"
      :prepend-icon="prependedIcon"
      @click:append="appendIconCallback"
      @click:prepend="prependIconCallback"
      @focus="checkStatus(true)"
      @blur="checkStatus(false)"
      v-bind="$attrs"
      v-on="$listeners"
      v-click-outside="clickOutsideCallback"
    >
    <template v-if="keyInfo === 'keyInfo-link'" v-slot:prepend-inner>
      <div class="prepend-icon-link text-left">
        <Link color="#898989" width="16" height="16" />
      </div>
    </template>
    </v-text-field>
  </div>
  <div v-else>
    <slot />
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import Link from '@/components/common/svg/Link';

export default {
  name: 'BaseInput',
  model: {
    prop: 'value',
    event: 'input',
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    Link,
  },
  props: {
    mode: {
      type: String,
      default: 'edit',
    },
    inputLabel: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
    keyInfo: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    appendIconCallback: {
      type: Function,
      default: () => {},
    },
    prependIconCallback: {
      type: Function,
      default: () => {},
    },
    clickOutsideCallback: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    checkStatus(status) {
      this.$emit('inputActive', status);
    },
  },
  computed: {
    appendedIcon() {
      if (this.appendIcon) {
        return this.appendIcon;
      }
      if (this.status === 'error') {
        return 'mdi-alert-circle-outline';
      }
      return null;
    },
    prependedIcon() {
      if (this.prependIcon) {
        return this.prependIcon;
      }
      return null;
    },
    inputStatus() {
      return `${this.status}State`;
    },
  },
};
</script>

<style lang="scss" scoped>
  .input-field {
    height: 60px;
  }
  ::v-deep .v-input input {
    max-height: 36px !important;
  }
  ::v-deep .errorState.v-text-field--solo .v-input__control > .v-input__slot {
    border: solid 1px #ff0808 !important;
  }
  ::v-deep .successState.v-text-field--solo .v-input__control > .v-input__slot {
    border: solid 1px #00c48c !important;
  }
  ::v-deep .disabledState.v-text-field--solo .v-input__control > .v-input__slot {
    border: solid 1px $border2 !important;
    background-color: $border2 !important;
  }
  ::v-deep .normalState.v-text-field--solo .v-input__control > .v-input__slot {
    border: solid 1px $border2 !important;
  }
  ::v-deep .normalState.primary--text {
    color: $charcoalBlack !important;
    caret-color: $charcoalBlack !important;
  }
  ::v-deep .v-text-field--outlined.v-input--is-focused fieldset,
  .v-text-field--outlined.v-input--has-state fieldset {
    border: 1px solid currentColor !important;
  }
  ::v-deep ::placeholder { /* FireFox */
    font-family: $fontFamily1;
  }
  ::v-deep :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: $fontFamily1;
  }
  ::v-deep ::-ms-input-placeholder { /* Microsoft Edge */
    font-family: $fontFamily1;
  }
</style>
